import React from 'react'
import { Suspense } from 'react';
import { lazy } from 'react';
import { memo } from 'react';
// import ReCAPTCHA from "react-google-recaptcha";
const ReCAPTCHA =lazy(() => import("react-google-recaptcha"))
 const CaptchaComp =memo(({setCaptcha}) => {

     
  return (
    <Suspense>


    <ReCAPTCHA
            
                   sitekey="6LfAriATAAAAAAE0YnAQzOBtCLOfwNpuWHd73k6a"
                  onChange={(value) => setCaptcha(value)}
                />
      </Suspense>
  )
})
export default CaptchaComp;