import React from 'react'
import { Link } from 'react-router-dom'
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import "./style.css"
export default function BtnTopAllShoping() {
  return (
    <>

      <div className="div-top-shoping text-center p-3 mb-3">
        <span className="pl-5"> <BsArrowRight /> </span>
        جميع منتجاتنا
        <Link aria-current="page" to={"/ListProdects"}><button className="btn">تسوق الآن</button></Link>
        <span ><BsArrowLeft /></span>

      </div></>
  )
}
