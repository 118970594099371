import React, { memo, useState } from "react";
import "./style.css"
import ReactApexChart from "react-apexcharts";

const ApexChart = ({chartdata}) => {
let alldata =[]
let allprodect =[]
let allorder =[]

    const datacgart = {
      series: [{
        name: 'عدد الطلبات',
        data: allorder
      }, {
        name: 'عدد المنتجات',
        data: allprodect
      }],
      
      options: {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datemonth',
          categories:alldata,  
         

        },
      
        tooltip: {
          x: {
            format: 'dd/MM/yy'
          },
        },
      },
    
    
    };
  




    


    if(chartdata && chartdata.data){
      Object.keys( chartdata.data.chart_date).forEach(function(key, index){
        alldata.push(key)
        allorder.push(chartdata.data.chart_date[`${key}`].user_orders_count)
        allprodect.push(chartdata.data.chart_date[`${key}`].user_orders_products)
        
  
      });
    }
     return (
      

<div id="chart">
<ReactApexChart options={datacgart.options} series={datacgart.series} type="area" height={300} />
</div>


    );

}
export default memo(ApexChart)  
