import React, { useState } from "react";
import CartProfile from "./CartProfile";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import ApexChart from "./charting";
import { Helmet } from 'react-helmet-async';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editCustomer, user_orders_chart } from "../../services/action/action";
import FormData from 'form-data'

const ProfilePage = () => {

  const [edite, setsedite] = useState(true)
  const [phone, setphone] = useState(null)
  const [isShowingAlert, serisShowingAlert] = useState(false)
  const [name, setname] = useState(null)
  const [password, setpassword] = useState(null)
  const [to_date, setto_date] = useState(null)
  const [from_date, setfrom_date] = useState(null)
  const [code, setcode] = useState("966")
  const dispacth = useDispatch()
  const data = JSON.parse(localStorage.getItem("user"))
  let chartdata = useSelector(state => state.chart)
  const path = window.location.href
  const datauser = useSelector(state => state.user)
  var today = new Date().toISOString().split('T')[0];

  const editebtn = () => {
    setsedite(false)

  }
  useEffect(() => {
    window.scrollTo(0, 0)
    const dataFform = { user_id: data.data.user_id }
    dispacth(user_orders_chart(dataFform, data.data.token))
  }, [])

  const hendling = (event) => {
    event.preventDefault();
    const dataEdite = { name: name, phone: `+${code}${phone}`, password: password }
    //  user_id
    dispacth(editCustomer(dataEdite, data.data.token, data.data.user_id))

  }

  useEffect(() => {
    if (datauser.success == true) {
      setsedite(true)
      serisShowingAlert(true)
    }
    setTimeout(() => {
      serisShowingAlert(false)
    }, 3000)
    if (data && data.data) {
      setphone(`${data.data.phone}`)
      setname(data.data.user_name)
    }

  }, [datauser])

  const uplodimg = (e) => {
    var formdata = new FormData();
    formdata.append("image", e.target.files[0]);
    dispacth(editCustomer(formdata, data.data.token, data.data.user_id))
  }
  const hendlingchart = (e) => {
    e.preventDefault()
    const dataform = { from_date: from_date, to_date: to_date, user_id: data.data.user_id }
    dispacth(user_orders_chart(dataform, data.data.token))
  }
  return (
    <>
      <Helmet>
        <title>my profile </title>
        <meta
          name="description"
          content="Your account details on WAV water company website  "
        />
        <link rel="canonical" href={path} />
      </Helmet>
      <div
        className={`alert  back-light main-color ${isShowingAlert ? 'alert-shown ' : 'alert-hidden'}`}
      >
        تم التحديث

      </div>
      <h1 className="visually-hidden">Your account details</h1>
      <div className="top-profile-div">
        <div className="text-start container">

          <input className="d-none" type="file" id="uplodimg" onChange={uplodimg} accept="image/png, image/gif, image/jpeg" />
          <label htmlFor="uplodimg" className="btn add-img">
            أضف صورة
          </label>
        </div>
      </div>
      <section className="profile-section container " >
        <div className="row">
          <div className="col col-12 col-md-4 ">
            <div className=" cart-profil-sec">
              <CartProfile />
            </div>

          </div>
          <div className="col col-12 col-md-8 ">
            <div className="charting p-3">

              {chartdata && <ApexChart chartdata={chartdata} />}

              <form onSubmit={hendlingchart} className=" main-color   ">
                <div className="row">
                  <div className=" row col-12 col-sm-6">
                    <label for="inputPassword" className="col-sm-2 col-form-label">من</label>
                    <div className="col-sm-10">
                      <input max={today} onChange={(e) => setfrom_date(e.target.value)} type="date" className={"form-control form-control date-input w-100 ml-4 p-2 mt-2 mb-2"} id="setfrom_date" name="birthday" placeholder="التاريخ والوقت" />
                    </div>
                  </div>
                  <div className=" row col-12 col-sm-6">
                    <label for="inputPassword" className="col-sm-2 col-form-label">الي</label>
                    <div className="col-sm-10">
                      <input max={today} onChange={(e) => setto_date(e.target.value)} type="date" className={"form-control form-control date-input w-100 ml-4 p-2 mt-2 mb-2"} id="setto_date" name="birthday" placeholder="التاريخ والوقت" />
                    </div>
                  </div>
                  <div className="col-12 text-center"> <button disabled={!to_date && !from_date} className=" w-50 btn btn-login py-1" type="submit">ارسال</button></div>
                </div>
              </form>
            </div>

            <div className="row mt-3">
              <div className="col div-point  d-flex justify-content-between align-items-center p-2">
                <div>لديك   0 نقاط</div>

                <button className="btn">استبدال</button>
              </div>
              <div className="col ">
                <div className="div-charge col d-flex justify-content-between align-items-center p-2">
                  <div>رصيدك الحالي <br /> 0ر.س</div>
                  <button className="btn ">شحن الرصيد</button>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div className="form-profile">
          {edite && <div className="main-color edit_btn text-start" onClick={editebtn}> تعديل<MdModeEditOutline /></div>}
          <form onSubmit={(event) => hendling(event)}>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label main-color"> <FaUserAlt />الاسم  </label>
              <input readOnly={edite} type="text" onChange={(e) => setname(e.target.value)} value={name} className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
            </div>
            <hr />
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput12" className="form-label main-color"> <RiLockPasswordLine />كلمة المرور     </label>
              <input readOnly={edite} type="password" onChange={(e) => setpassword(e.target.value)} className="form-control" id="exampleFormControlInput12" placeholder="........" />
            </div>
            <hr />


            <label htmlFor="exampleFormControlInput31" className="form-label main-color" >  <IoMdCall /> رقم الجوال </label>
            <div className="input-group ltr mb-3">


              <select name="" readOnly={edite} onChange={(e) => setcode(e.target.value)} className="main-color border-0" id="">
                <option value="966">+966</option>
              </select>
              <input type="number" readOnly={edite} onChange={(e) => setphone(e.target.value)} value={phone} id="exampleFormControlInput31" className="form-control" aria-label="Text input with dropdown button" />
            </div>
            <hr />

            {!edite && <div className="text-center d-flex">
              <button className=" btn btn-login " type="submit"> حفظ التعديل</button>

            </div>}
          </form>
        </div>
      </section>

    </>
  );
};

export default ProfilePage;