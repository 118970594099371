import React, { useEffect, useState } from "react";
import Card from "../../component/card/card";
import DetailsProdect from "../../component/detailsProdect/detailsProdect"
import "./style.css"
import {  useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { AddCart, AddCartfree, addfavProductItem, deletefavProductItem, details, favdetails, Listprodect } from "../../services/action/action";
import RetingPage from  "../../lib/retingPage/RetingPage";
 import BeatLoader from "react-spinners/BeatLoader";
import { Helmet } from 'react-helmet-async';
import { AddFavHook } from "../../component/CustomHooks/AddFavHook";
 
const DetailsPage = () => {
  const dispatch = useDispatch()

  const [detailes, setDetailes] = useState();
  const [reting, setreting] = useState(false);
  const [cart, setcart] = useState()
  const [contentpop, setcontentpop] = useState()

    const [listProdects, setListProdects] = useState("not")
 
  useEffect(() => {
    dispatch(Listprodect())
    window.scrollTo(0, 0)
    
  }, [])
    const allprodect = useSelector(state => state.prodects);
  useEffect(() => {
    setListProdects(allprodect.data)
  }, [allprodect])

  let lang = "ar";

   const param = useParams();
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[param.id])
  const  user = JSON.parse(localStorage.getItem('user'));
  const faVchange = useSelector(state => state.addfavProduct);

  useEffect(() => {
    if(user && user.data &&user.data.token  ){
      
      dispatch(favdetails(param.id,user.data.token))
    }else{
      dispatch(details(param.id))

    }

  }, [param.id,faVchange])
  const data = useSelector(state => state.details)
  useEffect(() => {
    setDetailes(data.data)
  
  }, [data])
  const navigate = useNavigate();

//  /           check  product_business_id
  if(detailes && detailes[0] &&  detailes[0].product_business_id !== 144){
    navigate("/")

    }



  const carShoping = useSelector(state => state.cards);
      useEffect(() => {
    setcart(carShoping)
 
  }, [carShoping])

  const  itemsfree = JSON.parse(localStorage.getItem('itemsfree'));


  const addCart = (iteme) => {
    const oneprodect = listProdects.products_list.filter(element => {
      if (element.id == iteme.id) {
        return element
      }
    });
    const quantitylocal = iteme.minimum  == 0 ? 1 : iteme.minimum

    if(oneprodect[0] == undefined ){
   
      oneprodect.push(iteme)

    }
    const data = {
      ...oneprodect[0],
      quantity:Number(quantitylocal)
    }
    const datafree = {
      ...oneprodect[0],
      quantity:Number(quantitylocal)
    }
    
    if (cart) {
      if (cart.find((product) => product.id === data.id)) {

      } else {
        dispatch(AddCart([...cart, data]))
        if (window.snaptr) {
          // Send a View Content event to the Snapchat servers
          window.snaptr('track', 'ADD_CART', {
            content_name: iteme.product_ar_name,
            content_ids: [iteme.id],
            content_type: 'product',
            value: iteme.final_price,
            currency: 'SAR'
          });
        }  
        if( oneprodect[0].presents && oneprodect[0].presents[0] && iteme.minimum == oneprodect[0].presents[0].qty ){
          const content =(
            <>
           <p>لقت تم  اضافة  عدد  <strong> {oneprodect[0].presents[0].gift}</strong> كرتون  مجانا   </p>
            <p>من عرض {iteme.product_ar_name}</p>
            </>
          )
         setcontentpop( content)
         document.getElementById("btn-test").click()
               if (itemsfree) {
              //  get index numberitemsfree 
              if (itemsfree.find((product) => product.id === datafree.id)) {
        
                let findindex = itemsfree.findIndex(obj => obj.id === iteme.id);
                itemsfree[findindex].quantity =  oneprodect.presents[0].gift ;
                dispatch(AddCartfree(itemsfree))
               
              } else {
                datafree.quantity =oneprodect[0].presents[0].gift 
        
                dispatch(AddCartfree([...itemsfree, datafree]))
        
              }
        
            } else {
        
              datafree.quantity =  oneprodect[0].presents[0].gift 
              dispatch(AddCartfree([datafree]))
            
           }
         }
      }

    } else {

      dispatch(AddCart([data]))
      if (window.snaptr) {
        // Send a View Content event to the Snapchat servers
        window.snaptr('track', 'ADD_CART', {
          content_name: iteme.product_ar_name,
          content_ids: [iteme.id],
          content_type: 'product',
          value: iteme.final_price,
          currency: 'SAR'
        });
      }  
      if( oneprodect[0].presents && oneprodect[0].presents[0] && iteme.minimum == oneprodect[0].presents[0].qty ){
        const content =(
          <>
         <p>لقد تم  اضافة  عدد  <strong> {oneprodect[0].presents[0].gift}</strong> كرتون  مجانا   </p>
          <p>من عرض {iteme.product_ar_name}</p>
          </>
        )
       setcontentpop( content)
       document.getElementById("btn-test").click()
             if (itemsfree) {
            //  get index numberitemsfree 
            if (itemsfree.find((product) => product.id === datafree.id)) {
      
              let findindex = itemsfree.findIndex(obj => obj.id === iteme.id);
              itemsfree[findindex].quantity =  oneprodect.presents[0].gift ;
              dispatch(AddCartfree(itemsfree))
             
            } else {
              datafree.quantity =oneprodect[0].presents[0].gift 
      
              dispatch(AddCartfree([...itemsfree, datafree]))
      
            }
      
          } else {
      
            datafree.quantity =  oneprodect[0].presents[0].gift 
            dispatch(AddCartfree([datafree]))
          
         }
       }
    }

  }

  const addCartrelat = (iteme) => {

    const quantitylocal = iteme.minimum  == 0 ? 1 : iteme.minimum

      const items = JSON.parse(localStorage.getItem('items'));
      const data = {
        ...iteme,
        quantity:Number(quantitylocal) 
      }
      const datafree = {
        ...iteme,
        quantity:Number(quantitylocal) 
      }
     
      if (items) {
        if (items.find((product) => product.id === iteme.id)) {

        } else {
          dispatch(AddCart([...items, data]))
          if (window.snaptr) {
            // Send a View Content event to the Snapchat servers
            window.snaptr('track', 'ADD_CART', {
              content_name: iteme.product_ar_name,
              content_ids: [iteme.id],
              content_type: 'product',
              value: iteme.final_price,
              currency: 'SAR'
            });
          }      
          if( iteme.presents && iteme.presents[0] && iteme.minimum == iteme.presents[0].qty ){
       
            const content =(
              <>
             <p>لقت تم  اضافة  عدد  <strong> {iteme.presents[0].gift}</strong> كرتون  مجانا   </p>
              <p>من عرض {iteme.product_ar_name}</p>
              </>
            )
            setcontentpop( content)
            document.getElementById("btn-test").click()
           if (itemsfree) {
          //  get index numberitemsfree 
          if (itemsfree.find((product) => product.id === datafree.id)) {
    
            let findindex = itemsfree.findIndex(obj => obj.id === iteme.id);
            itemsfree[findindex].quantity =  iteme.presents[0].gift ;
            dispatch(AddCartfree(itemsfree))
           
          } else {
            datafree.quantity =iteme.presents[0].gift 
    
            dispatch(AddCartfree([...itemsfree, datafree]))
    
          }
    
        } else {
    
          datafree.quantity =  iteme.presents[0].gift 
           dispatch(AddCartfree([datafree]))
        
       }
     }
         
        }

      } else {
         if( iteme.presents && iteme.presents[0] && iteme.minimum == iteme.presents[0].qty ){
       
              const content =(
                <>
               <p>لقت تم  اضافة  عدد  <strong> {iteme.presents[0].gift}</strong> كرتون  مجانا   </p>
                <p>من عرض {iteme.product_ar_name}</p>
                </>
              )
              setcontentpop( content)
              document.getElementById("btn-test").click()
             if (itemsfree) {
            //  get index numberitemsfree 
            if (itemsfree.find((product) => product.id === datafree.id)) {
      
              let findindex = itemsfree.findIndex(obj => obj.id === iteme.id);
              itemsfree[findindex].quantity =  iteme.presents[0].gift ;
              dispatch(AddCartfree(itemsfree))
             
            } else {
              datafree.quantity =iteme.presents[0].gift 
      
              dispatch(AddCartfree([...itemsfree, datafree]))
      
            }
      
          } else {
      
            datafree.quantity =  iteme.presents[0].gift 
             dispatch(AddCartfree([datafree]))
          
         }
       }
        dispatch(AddCart([data]))
        if (window.snaptr) {
          // Send a View Content event to the Snapchat servers
          window.snaptr('track', 'ADD_CART', {
            content_name: iteme.product_ar_name,
            content_ids: [iteme.id],
            content_type: 'product',
            value: iteme.final_price,
            currency: 'SAR'
          });
        }  
      }
    


  }
  
 
  
  const Retingsection = () => {
    setreting(!reting)
  }
  const path =window.location.href;      

  const  [addfavProduct] = AddFavHook()
 
  return (
    <>

      <Helmet>
        <title>Product details  </title>
        <meta
          name="description"
          content="Details of wav mineral water company products"
        />
        <link rel="canonical" href={path} />
      </Helmet>
      <button type="button" className="btn d-hidden" id="btn-test" data-bs-toggle="modal" data-bs-target="#freeprodect">
 
 </button>
      <div className="modal fade " id="freeprodect" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                     <div className="modal-dialog">
                                         <div className="modal-content">
                                           <div>
                                             <div className="p-3 text_second_color fs-6 text-center">{contentpop}</div> 
                                           </div>    
                                             <div className="text-center">
                                                 <button aria-label="Close" type="button" className="btn btn_pop_massge" data-bs-dismiss="modal">استمرار الطلب</button>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
      <h1 className="visually-hidden">Details of wav Company products</h1>
      <DetailsProdect  addfavProduct={addfavProduct} lang={lang} Retingsection={Retingsection} addCart={addCart} detailes={detailes}  setcontentpop={setcontentpop}/>
      {reting && <section className="reting-section">
        <RetingPage />
      </section>}
 
      <section className="more-prodects pt-5 pb-5">
        <div className="container">
          <h3 className="mb-4 main-color">منتجات ذات صلة</h3>
          <div className="row">
            {
              detailes ? detailes[0].productRelation.map((item) => {
                return (
                  <div key={item.id} className="col col-12 col-sm-6 col-md-4 col-lg-3"> <Card addfavProduct={addfavProduct} addCartrelat={addCartrelat} item={item} /></div>
                )
              }) : <div className='spinner'>
                <BeatLoader color="#36d7b7" margin={8}
                  size={20}
                />
              </div>
            }
          </div>
        </div>

      </section>
    </>
  )
}

export default DetailsPage;