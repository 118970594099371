import './App.css';
import React, { lazy, useEffect, useState } from 'react';
import HomePage from './Pages/homePage/homePage';
 import {   Routes, Route } from "react-router-dom";
import SigIn from './Pages/signInpage/signIn';
import SigUp from './Pages/singup/singup'
import ForgectPass from './Pages/forgectpass/forgetPass';
import DetailsPage from './Pages/detailesPage/detailePage';
import RetingPage from './lib/retingPage/RetingPage';
import ShopingCar from './component/shopin-car/shipngCar';
import ShopingCarPage from './Pages/shopingCarPage/shopingCarPage';
import ShippingData from './Pages/ShippingDataPage/ShippingData';
import ProfilePage from './Pages/profilePage/ProfilePage';
import PageFollowOrder from './Pages/pagefollowOrder/pagefollowOrder';
import PrivateRoute from './component/PrivateRoute/PrivateRoute';
import Pyment from './Pages/CompletPay/pyment';
import NemPass from './Pages/forgectpass/newPass';
import CompletPay from './Pages/CompletPay/CompletPay';
import SelectShipping from './Pages/SelectShipping/SelectShipping';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import ConfirmNumber from './Pages/singup/confirmNumber'
import AboutPage from './Pages/aboutPage/aboutPage';
import ReplacementRefund from './Pages/replacementRefund/ReplacementRefund';
import { Suspense } from 'react';
const Footer = lazy(()=> import('./layout/footer/footer')) ;
const Header = lazy(()=> import('./layout/header/header')) ;
const MessengerCustomerChat = lazy(()=> import('react-messenger-customer-chat')) ;
const FavlistProdects = lazy(()=> import('./Pages/FavlistProdects/FavlistProdects')) ;
const ListPresents = lazy(()=> import('./Pages/ListPresents/ListPresents')) ;
const ListProdects = lazy(()=> import('./Pages/listProdects/ListProdects')) ;
const MyOrders = lazy(()=> import('./Pages/myOrders/MyOrders')) ;
const Complaints = lazy(()=> import('./Pages/complaints/Complaints')) ;
const SupportPage = lazy(()=> import('./Pages/support/SupportPage')) ;
const ConditionsAR = lazy(()=> import('./Pages/CONDITIONSAR/CONDITIONSAR')) ;
function App() {
    const [isIOS, setIsIOS] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);
    const [Remember, setRemember] = useState(true);
useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const rememberME = sessionStorage.getItem("check_divce")
    setIsIOS(/iPad|iPhone|iPod/.test(userAgent));
    setIsAndroid(/Android/.test(userAgent));
    if(rememberME){
        setRemember(false)
    }
  }, []);
  const rememberME =()=>{
    sessionStorage.setItem("check_divce",true)
    setRemember(false)
}
    return (
        < >
             {
     (isIOS || isAndroid) && Remember && <div className="pop_divce_phone"> <div className=" ">
      {isAndroid && < p > هناك نسخة مُتاحة من تطبيق واف لنظام الأندرويد على متجر جوجل بلاي.</p>}
      {isIOS &&    < p>هناك نسخة مُتاحة من تطبيق واف   للايفون  على   متجر التطبيقات   </p>} 
      <div className='d-flex'>
      <button onClick={rememberME}  className="btn btn_pop_massge w-50 mb-0 mx-1 py-1">استمرار </button>
       { isAndroid && <a onClick={rememberME} rel="noreferrer" aria-label=" download android "  className="btn btn_pop_massge w-50 mb-0 mx-1 py-1" target="_blank"   href='https://play.google.com/store/apps/details?id=com.project.wav'>تحميل  </a>} 
       { isIOS &&  <a onClick={rememberME} rel="noreferrer" aria-label=" download android "  className="btn btn_pop_massge w-50 mb-0 mx-1 py-1" target="_blank"  href='https://apps.apple.com/us/app/%D9%88%D8%A7%D9%81/id1672971914'>تحميل  </a> }
      </div>
    </div> </div>}
        <Suspense>
                <Header />
                <MessengerCustomerChat
                pageId="107948888557139"
                appId="1657536821315818"
            />
        </Suspense>
                <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/homePage" element={<HomePage />} />   
                <Route path="/confirmNumber" element={<ConfirmNumber />} />    
                <Route path="/signIn" element={<SigIn />} />     
                <Route path="/SigUp" element={<SigUp />} />         
                <Route path="/Conditions" element={<Suspense fallback={  <div  className="spinnerMAin ">Loading...</div>}>  <ConditionsAR /></Suspense>} />   
                <Route path="/privacy-policy" element={ <Suspense fallback={  <div  className="spinnerMAin ">Loading...</div>}>  <ConditionsAR /> </Suspense>} />   
                <Route path="/selectShipping" element={<SelectShipping/>} />   
                <Route path="/404" element={<NotFoundPage/>} />   
                <Route path="*" element={<NotFoundPage/>} />     
                <Route path="/Pyment" element={<Pyment />} />     
                <Route path="/ReplacementRefund" element={<ReplacementRefund />} />    
                <Route path="/Pyment/:id" element={<Pyment />} />     
                <Route path="/AboutPage" element={<AboutPage />} />      
                <Route path="/ForgectPass/NemPass" element={<NemPass />} />     
                <Route path="/ForgectPass"  element={<ForgectPass />} />      
                <Route path="/products/:id"  element={ <DetailsPage />} />      
``                 <Route path="/SupportPage"  element={<Suspense fallback={  <div  className="spinnerMAin ">Loading...</div>}> <SupportPage /></Suspense> } />   
                <Route path="/products"  element={<Suspense fallback={  <div  className="spinnerMAin ">Loading...</div>}>   <ListProdects /> </Suspense>} />   
                <Route path="/Presents"  element={<Suspense fallback={  <div  className="spinnerMAin ">Loading...</div>}>  <ListPresents /></Suspense>} />   
                <Route path="/Complaints"  element={<Suspense fallback={  <div  className="spinnerMAin ">Loading...</div>}> <Complaints /> </Suspense>} />    
                <Route path="/RetingPage"  element={ <PrivateRoute><RetingPage /></PrivateRoute>} />   
                <Route path="/ShopingCarPage"  element={<ShopingCarPage />} />   
                <Route path="/ShippingData"  element={<PrivateRoute><ShippingData /></PrivateRoute>} />   
                <Route path="/ProfilePage"  element={ <PrivateRoute><ProfilePage /></PrivateRoute>} />   
                <Route path="/MyOrders"  element={<Suspense fallback={  <div  className="spinnerMAin ">Loading...</div>}><PrivateRoute><MyOrders /></PrivateRoute>  </Suspense> } />
                <Route path="/pageFollow/:id"  element={<PrivateRoute><PageFollowOrder /></PrivateRoute>} />
                <Route path="/FavlistProdects"  element={<Suspense fallback={  <div  className="spinnerMAin ">Loading...</div>}> <PrivateRoute><FavlistProdects /></PrivateRoute> </Suspense>} />
                <Route path="/CompletPay/:id"  element={<PrivateRoute><CompletPay /></PrivateRoute>} />
                <Route  element={<NotFoundPage/>} />
                </Routes>
                <Suspense>
                    <Footer />  
                </Suspense>
                <ShopingCar/>
        </ >
    )
}
export default App;
