import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./style.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import { detailesOrder } from "../../services/action/action";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { Helmet } from 'react-helmet-async';

const PageFollowOrder = () => {
    const param = useParams();
    const dispatch = useDispatch()
    const usertoken = JSON.parse(localStorage.getItem("user"))

    useEffect(() => {
        dispatch(detailesOrder(param.id, usertoken.data.token))
        window.scrollTo(0, 0)

    }, [])
    const data = useSelector(state => state.detailesOrder)
    var months = ["يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
        "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
    ];
    var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];
    var delDateString = ""
    if (data && data.message == "success") {

        var date = new Date(data.data.delivery_date);



        delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
    }
    const path = window.location.href

    return (
        <>
            <Helmet>
                <title>Order details </title>
                <meta
                    name="description"
                    content="Details of your order that you purchased from wav website"
                />
                <link rel="canonical" href={path} />
            </Helmet>
            <h1 className="visually-hidden">Details of your order that you purchased from wav website</h1>
            {

                data && data.status == true ? <section className="pageFollowOrder container">
                    <div className="d-flex justify-content-between ">
                        <h5 className="main-color">تفاصيل الطلب</h5>
                        <Link to={`/CompletPay/${data.data.reference}`} className="fs-5 link-follow"> <FaMapMarkerAlt />تتبع طلبك </Link>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12 col-sm-5">
                            <div className="totale-prodects">
                                <h5 className="main-color">ملخص الطلب</h5>
                                <div className="d-flex justify-content-between ">
                                    <div>تكلفة الطلب:</div>
                                    <div>{data.data.subtotal} ر.س</div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between ">
                                    <div> التوصيل:</div>
                                    <div>{data.data.shipping} ر.س</div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between ">
                                    <div> الضريبة:</div>
                                    <div>{data.data.tax} ر.س</div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between ">
                                    <div> الاجمالي:</div>
                                    <div className="main-color">{data.data.total} ر.س</div>
                                </div>
                            </div>
                            <div className="list-prodects shadow-sm  p-2  mb-5">
                                <div className=" d-flex justify-content-between pb-2">
                                    <h5>محتويات الطلب</h5>


                                </div>
                                {
                                    data.data.details.map((state) => {
                                        return (<>
                                            <div key={state.id} className="d-flex ml-2">
                                                <div className="position-relative img-shoping">
                                                    <img className="w-100 h-auto" width={100} height={100} src={state.product.image} alt="" />
                                                </div>
                                                <div className="">
                                                    <h5>{state.name}</h5>
                                                    <div className="d-flex">
                                                        <p className="mb-0 mx-2">العدد{state.qty}  </p>
                                                        {state.free_qty >= 1 && "+"}
                                                        {state.free_qty >= 1 && <p className=" mx-2"> {state.free_qty} مجاني</p>}
                                                    </div>
                                                    <p className="fs-5 main-color"> {state.total_price} ر.س</p>
                                                </div>
                                            </div>

                                        </>)
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-12 col-sm-7">
                            {data && data.data.mosque_data ? <div className="cheked-data mt-3 pb-3">
                                <h5>بيانات الشحن</h5>
                                <h6 className="main-color"> {data.data.mosque_data.name}  </h6>
                                <p>{data.data.mosque_data.address}</p>
                                <p> {data.data.mosque_data.phone}</p>
                            </div> :
                                <div className="cheked-data mt-3 pb-3">
                                    <h5>بيانات الشحن</h5>
                                    <h6 className="main-color"> {data.data.first_name} {data.data.last_name}</h6>
                                    <p>{data.data.address1.substr(0, data.data.address1.indexOf('-'))}</p>
                                    <p> {data.data.address1.substr(data.data.address1.indexOf('-'))}</p>
                                </div>
                            }
                            <div className="cheked-data mt-3">
                                <h5>بيانات الطلب</h5>
                                <div>حجز طلب يحتوي علي  {data.data.details.length}  عناصر  | يصل    <span className="main-color"> {delDateString}</span>      | التكلفة <span className="main-color">{data.data.total} ر.س</span></div>
                            </div>
                            <div className="cheked-data mt-3 pb-3">
                                <h5> وسيلة الدفع</h5>
                                <h6 className="main-color"> الدفع {data.data.payment_method}</h6>



                                {
                                    data && data.data.payment_status === 3 ?
                                        <p>  تم دفع قيمة الطلب   <span className="main-color"> {data.data.total} ر.س </span>         </p>
                                        :
                                        <div> <p>ستدفع قيمة الطلب <span className="main-color"> {data.data.total} ر.س </span>      للمندوب عندما يصل اليك</p>
                                            <Link to={`/Pyment/${data.data.reference}`}> <button className="btn add-card w-50 mt-3">الدفع الان </button> </Link>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                </section> : <div className='spinner'>
                    <BeatLoader color="#36d7b7" margin={8}
                        size={20}
                    />
                </div>

            }

        </>
    )
}
export default PageFollowOrder;