import * as React from 'react';
import { BsCheckLg } from "react-icons/bs";
import { MdNordicWalking } from "react-icons/md";
import { BsFillBagCheckFill } from "react-icons/bs";
import { FaShippingFast } from "react-icons/fa";
import { memo } from 'react';




const FollowStep = memo(({ data }) => {




  var months = ["يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
    "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
  ];
  var days = ["اﻷحد", "اﻷثنين", "الثلاثاء", "اﻷربعاء", "الخميس", "الجمعة", "السبت"];
  const dateOrder = (item) => {
    var date = new Date(item.add_date);
    let delDateString = days[date.getDay()] + ', ' + date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
 return delDateString;

  }
  const stepEnd  = data.data && data.data.order_histories.length 
  return (

    <>
    
      <div className="container padding-bottom-3x mb-1">
        <div className=".cardstep mt-5">
          <div className="card-body">
            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
      
           <div className={stepEnd>= 1 ? "step completed" : "step "}>
                      <div className="step-icon-wrap">
                        <div className="step-icon"></div>
                      </div>
                      <h4 className={stepEnd >=1 ? "step-title main-color" : "step-title "} >{stepEnd >= 1 && data.data.order_histories[0].status.name_ar}</h4>
                      <p> { stepEnd >= 1 &&  dateOrder(data.data.order_histories[0])}</p>
                    </div>

              <div className={stepEnd >= 2 ? "step completed" : "step "}>
                <div className="step-icon-wrap">
                  <div className="step-icon"> <span><BsCheckLg /></span></div>
                </div>
                <h4 className={stepEnd >= 2 ? "step-title main-color" : "step-title "} >  {stepEnd >= 2 && data.data.order_histories[1].status.name_ar} </h4>
                <p> { stepEnd >= 2 &&  dateOrder(data.data.order_histories[1])} </p>
              </div>
              <div className={stepEnd >= 3 ? "step completed" : "step "}>
                <div className="step-icon-wrap">
                  <div className="step-icon"><MdNordicWalking /></div>
                </div>
                <h4 className={stepEnd >= 3 ? "step-title main-color" : "step-title "}>    {stepEnd >= 3 && data.data.order_histories[2].status.name_ar}  </h4>
                <p> { stepEnd >= 3 &&  dateOrder(data.data.order_histories[2])}</p>
              </div>
              <div className={stepEnd >= 4 ? "step completed" : "step "}>
                <div className="step-icon-wrap">
                  <div className="step-icon"> <FaShippingFast /></div>
                </div>
                <h4 className={stepEnd >= 4 ? "step-title main-color" : "step-title "} > {stepEnd >= 4 && data.data.order_histories[3].status.name_ar}</h4>
                <p>{ stepEnd >= 4 &&  dateOrder(data.data.order_histories[3])}</p>
              </div>
              <div className={stepEnd >= 5 ? "step completed" : "step "}>
                <div className="step-icon-wrap">
                  <div className="step-icon"><BsFillBagCheckFill /></div>
                </div>
                <h4 className={stepEnd >= 5 ? "step-title main-color" : "step-title "} >   {stepEnd >= 5 && data.data.order_histories[4].status.name_ar} </h4>
                <p>   { stepEnd >= 5 &&  dateOrder(data.data.order_histories[4])} </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
})

export default FollowStep;