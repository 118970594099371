import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./style.css";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { FaAccusoft } from "react-icons/fa";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../../asset/images/delfould-image (1).webp";
import { memo } from "react";

const SliderItem = ({
  isShowingAlert,
  lang,
  listProdects,
  addCart,
  addfavProduct,
}) => {
  const [prodect, setprodect] = useState("not");
  const [cardprodect, setcardprodect] = useState();
  useEffect(() => {
    if (listProdects && listProdects.data && listProdects.data.products_list) {
      setprodect(listProdects.data.products_list);
    }
  }, [listProdects]);
  const settings = {
    dots: false,
    className: "center",
    infinite: false,

    slidesToShow: 5,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1444,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const data = useSelector((state) => state.cards);

  useEffect(() => {
    setcardprodect(data);
  }, [data]);
  const ifexit = (id) => {
    if (cardprodect && cardprodect.length >= 1) {
      return cardprodect.some((val) => val.id === id);
    }
  };
  const replaceImage = (error) => {
    //replacement of broken Image
    error.target.src = logo;
  };
  const trackViewContent = (iteme) => {
    // Initialize the Pixel

    // Send a View Content event to the Snapchat servers
    window.snaptr("track", "VIEW_CONTENT", {
      content_name: iteme.product_ar_name,
      content_ids: [iteme.id],
      content_type: "product",
      value: iteme.final_price,
      currency: "SAR",
      transaction_id: "111",
    });
  };
 
  return (
    <>
      <div className="slider-prodects">
        {prodect !== "not" ? (
          <div id="myptodects" className="slider-prodect">
            <h2> منتجاتنا </h2>
            <div
              className={`alert shadow  border border-2  back-light main-color ${isShowingAlert ? "alert-shown " : "alert-hidden"
                }`}
            >
              تم اضافة المنتج بنجاح
            </div>
            <Slider {...settings}>
              {prodect !== "not" &&
                prodect.map((item) => {
                  let price =
                    item.final_price == item.promotion_price
                      ? item.final_price
                      : item.promotion_price;
                  let promotion_price =
                    item.final_price > item.promotion_price
                      ? item.final_price
                      : null;
                  let cslcDic =
                    item.final_price > item.promotion_price
                      ? (((item.final_price - item.promotion_price) /
                        item.final_price) *
                      100).toFixed(2)
                      : null;

                  return (
                    <div key={item.id}>
                      <div className="card m-3">
                        <div className="reting-div">
                          {" "}
                          4.5
                          <span className="icon">
                            {" "}
                            <AiFillStar />
                          </span>
                        </div>
                        {cslcDic !== null && (
                          <div className="sale-div">
                            {" "}
                            <div>خصم %{cslcDic}</div>{" "}
                          </div>
                        )}
                        <Link
                          className="no-underline"
                          to={`/products/${item["id"]}`}
                          onClick={() => trackViewContent(item)}
                        >
                          <LazyLoadImage
                            src={item.image}
                            width={600}
                            height={400}
                            alt={item[`product_${lang}_name`]}
                            className="card-img-top"
                            onError={replaceImage}
                          />
                        </Link>

                        <div className="card-body">
                          <Link
                            className="no-underline"
                            to={`/products/${item["id"]}`}
                            onClick={() => trackViewContent(item)}
                          >
                            <h3 className="card-title fs-5 main-color">
                              {" "}
                              {item[`product_${lang}_name`].substring(0, 40)}
                            </h3>
                            <p className="card-title main-color">
                              {" "}
                              #{item["sku"]}
                            </p>

                            <p className="card-text">
                              {" "}
                              {item[`product_${lang}_desc`].substring(
                                0,
                                20
                              )}{" "}
                            </p>
                            <p className="fs-3">
                              {" "}
                              <span className="new-price">
                                {" "}
                                {` ${price} `}
                                <span className="fs-6">ر.س </span>
                              </span>{" "}
                              <span className="old-price">
                                {promotion_price}
                              </span>
                            </p>
                          </Link>
                          {/* <span>العدد:{item.qty}</span> */}

                          <div className="icon-card">
                            <span>
                              <FaAccusoft />
                            </span>
                            <button
                              aria-label="addfav"
                              onClick={() => addfavProduct(item)}
                              className={
                                item.fav_product !== 0
                                  ? " active btn added"
                                  : " active btn"
                              }
                            >
                              <AiFillHeart />{" "}
                            </button>
                            <button
                              aria-label="addCart"
                              disabled={item.product_show_in_store == 0}
                              onClick={() => addCart(item)}
                              className={
                                ifexit(item.id)
                                  ? " active btn added"
                                  : " active btn"
                              }
                            >
                              <AiOutlineShoppingCart />{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
            <div className="text-center btn-bottom mt-3">
              <Link to={"/products"}>
                <button type="button" className="btn btn-primary">
                  جميع المنتجات
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="spinner">
            <BeatLoader color="#36d7b7" margin={8} size={20} />
          </div>
        )}
      </div>
    </>
  );
};
export default memo(SliderItem);
