import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import { IoIosClose } from "react-icons/io";
import { AiFillStar } from "react-icons/ai";
import TotalOrders from "../TotalOrders/TotalOrders";
import { useDispatch, useSelector } from "react-redux";
import {
  addAddress,
  AddCart,
  AddCartfree,
  addressOrder,
  citiesAll,
  districtsAll,
} from "../../services/action/action";

import Select from "react-select";

import MapLocal from "../MapLoacal";
import SelectMosques from "../SelectMosques/SelectMosques";

const EnterAddres = ({ setActiveStep }) => {
  const [detaileAddress, setdetaileAddress] = useState("");
  const [address_type, seaddress_type] = useState(1);
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [district, setdistrict] = useState("");
  const [vaild, setvalid] = useState(false);
  const [saveaddres, setsaveaddress] = useState(false);
  const [ifloding, setifloding] = useState(true);
  const [mosquetyeId, setmosquetyeId] = useState("");
  const [typeShpoing, settypeShpoing] = useState("personal");
  let lang = "ar";
  const user = JSON.parse(localStorage.getItem("user"));
  const address_order = JSON.parse(localStorage.getItem("address_order"));

  const options2 = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    const crd = pos.coords;

    setlongitude(crd.longitude);
    setlatitude(crd.latitude);
  }

  function error(err) { }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success, error, options2);

    window.scrollTo(0, 0);
    seaddress_type(2);
    if (
      !address_order &&
      user &&
      user.data &&
      user.data.district &&
      user.data.address1
    ) {
      setdistrict({ value: user.data.district, label: user.data.address1 });
      setdetaileAddress(user.data.address1);
    } else if (address_order) {
      setdistrict(address_order.distValLab);
      seaddress_type(address_order.address_type);
      setdetaileAddress(address_order.address);
      setlongitude(address_order.longitude);
      setlatitude(address_order.latitude);
    }
  }, []);

  const districts = useSelector((state) => state.districts);

  const hendleingsub = (event) => {
    event.preventDefault();
    //   location valg

    // all data

    const data = {
      address: `${district.label} - ${detaileAddress}`,
      address_type,
      latitude,
      longitude,
      user_id: user.data.business_id,
      district: district.value,
      distValLab: district,
    };
    if (latitude && longitude) {
      if (user.data && user.data.address1) {
        dispach(addressOrder(data));
        localStorage.setItem("address_order", JSON.stringify(data));
      } else {
        dispach(addressOrder(data));
        dispach(addAddress(data, user.data.token));
        localStorage.setItem("address_order", JSON.stringify(data));
      }
      setsaveaddress(true);

      setTimeout(() => {
        setsaveaddress(false);
      }, 3000);
    } else {
      alert("يجب تفعيل الموقع علي المتصفح");
    }
  };
  const dispach = useDispatch();

  useEffect(() => {
    dispach(citiesAll());
    dispach(districtsAll());
  }, []);

  const itemsfree = JSON.parse(localStorage.getItem("itemsfree"));

  const prodects = useSelector((state) => state.cards);
  const remove = (iteme) => {
    const filtered = prodects.filter((item) => item.id !== iteme.id);
    dispach(AddCart(filtered));
    const filteredfree = itemsfree.filter((item) => item.id !== iteme.id);
    dispach(AddCartfree(filteredfree));

    const initialValue = 0;
    var total = 0;
    var tax_amount = 0;
    var price = 0;
    if (filtered && filtered.length > 0) {
      total = filtered.reduce(
        (accumulator, current) =>
          accumulator + Number(current.promotion_price) * Number(current.quantity),
        initialValue
      );
      tax_amount = filtered.reduce(
        (accumulator, current) =>
          Number(accumulator) +
          Number(current.promotion_price / ((100 / current.tax_amount)+1)) * current.quantity,
        initialValue
      );
      price = filtered.reduce(
        (accumulator, current) =>
          accumulator + current.price * current.quantity,
        initialValue
      );
    }

    const dateLocal2 = JSON.parse(sessionStorage.getItem("detailsCartTotal"));
    sessionStorage.setItem(
      "detailsCartTotal",
      JSON.stringify({
        total: total,
        tax_amount: tax_amount,
        price: price,
        shipping: dateLocal2.shipping,
      })
    );
  };

  const nextStep = () => {
    const addressLocal = JSON.parse(localStorage.getItem("address_order"));
    if (
      addressLocal &&
      addressLocal.latitude &&
      addressLocal.longitude &&
      typeShpoing === "personal"
    ) {
      //first check
      setActiveStep(1);
      setifloding(false);
      sessionStorage.removeItem("mosque_data");
    } else if (typeShpoing === "mosque" && mosquetyeId) {
      setActiveStep(1);
      setifloding(false);
      sessionStorage.setItem("mosque_data", JSON.stringify(mosquetyeId));
    } else {
      setvalid(true);
    }
  };

  const options = [
    districts &&
    districts.data &&
    districts.data.map((item) => {
      return (
        //  {}
        {
          value: item.id,
          label: item[`name_${lang}`] + "- ( " + item.city_name + ")",
        }
      );
    }),
  ];

  return (
    <>
      <section className="container section-checked mt-5">
        <div className="row">
          <div className="col-12 col-md-7">
            <form className="row form-checked py-2">
              <div className=" form-check d-flex format_chect_radio">
                <input
                  onChange={(e) => settypeShpoing(e.target.value)}
                  className="form-check-input"
                  type="radio"
                  name="typeShoping"
                  id="typeShoping_1"
                  value="personal"
                  checked={typeShpoing === "personal"}
                />
                <label className="form-check-label" htmlFor="typeShoping_1">
                  الشحن الي عنواني
                </label>
              </div>
              <div className="form-check d-flex mt-3">
                <input
                  onChange={(e) => settypeShpoing(e.target.value)}
                  className="form-check-input"
                  type="radio"
                  name="typeShoping"
                  id="typeShoping_2"
                  value="mosque"
                  checked={typeShpoing === "mosque"}
                />
                <label className="form-check-label" htmlFor="typeShoping_2">
                  الشحن الي مسجد
                </label>
              </div>
            </form>
            <div
              className={
                vaild ? " row valid  form-checked" : " row  form-checked"
              }
            >
              {typeShpoing === "personal" ? (
                <form
                  className="px-5"
                  onSubmit={(event) => hendleingsub(event)}
                >
                  <div
                    className={`alert alert-success ${saveaddres ? "alert-shown" : "alert-hidden"
                      }`}
                  >
                    <strong>تم الحفظ</strong>
                  </div>
                  <h5>بيانات الشحن</h5>
                  <p>
                    من فضلك املأ البيانات بطريقة صحيحة حتي يصل اليك الطلب في
                    العنوان الصحيح
                  </p>
                  <p className="fs-6">يصل المنتج في خلال 24 الي 48 ساعة- </p>

                  <div className="col-12 mt-3">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={true}
                      name="color"
                      options={options[0]}
                      onChange={(event) => {
                        setdistrict(event);
                      }}
                      value={
                        district && {
                          value: district.value,
                          label: district.label,
                        }
                      }
                    />
                  </div>

                  <div className="col-12 mt-4">
                    <textarea
                      required
                      onChange={(event) =>
                        setdetaileAddress(event.target.value)
                      }
                      type="text"
                      className="form-control"
                      placeholder="العنوان بالتفصيل"
                      name=""
                      id=""
                      cols="10"
                      rows="5"
                      value={detaileAddress}
                    ></textarea>
                  </div>
                  <div className="col-12 mt-3">
                    <select
                      required
                      onChange={(event) => seaddress_type(event.target.value)}
                      className="form-control"
                      name=""
                      value={address_type}
                    >
                      <option value="1">المنزل</option>
                      <option value="2">العمل</option>
                      <option value="3">مكان عام </option>
                    </select>
                  </div>
                  <div className="col-12 mt-3"></div>
                  <button
                    type="button"
                    className="btn btn-primary w-100 selectbtn "
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    data-bs-whatever="@mdo"
                  >
                    لتغيير موقعك
                  </button>
                  <br />
                  {/* \\map */}
                  <div
                    className="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        {latitude != null && longitude != null && (
                          <MapLocal
                            latitude={latitude}
                            longitude={longitude}
                            setlatitude={setlatitude}
                            setlongitude={setlongitude}
                          />
                        )}
                      </div>
                    </div>

                    <div className="modal-footer d-flex justify-content-center">
                      <div className="d-flex justify-content-center align-items-end">
                        <button
                          type="button"
                          className="btn add-card px-5"
                          data-bs-dismiss="modal"
                        >
                          حفظ
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* /////////////////////////// */}
                  <button
                    type="submit"
                    className="w-75  btn mt-4 m-auto add-card"
                  >
                    حفظ
                  </button>
                </form>
              ) : (
                <SelectMosques
                  setmosquetyeId={setmosquetyeId}
                  options={options}
                />
              )}
            </div>
          </div>

          <div className="col-12 col-md-5">
            <TotalOrders
              setifloding={setifloding}
              nextStep={nextStep}
              ifloding={ifloding}
              addressOrder={addressOrder}
            />
            <div className="prodects-list">
              {prodects &&
                prodects.map((item) => {
                  return (
                    <Fragment key={item.id}>
                      <div
                        key={item.id}
                        className="d-flex justify-content-between  mt-2    align-items-center"
                      >
                        <div className="d-flex ml-2">
                          <div className="position-relative  img-shoping">
                            <div className="div-reting  position-absolute ">
                              {" "}
                              4.5{" "}
                              <span className="star-active">
                                <AiFillStar />
                              </span>
                            </div>
                            <img
                              className=" border border-1 ml-2"
                              width={120}
                              height={120}
                              src={item.image}
                              alt=""
                            />
                          </div>
                          <div className="mx-2">
                            <h5 className="fs-6">
                              {item[`product_${lang}_name`]}
                            </h5>
                            <p className="mb-0">
                              الكمية:
                              {item.quantity}
                            </p>
                            <p className="fs-5 main-color">
                              {" "}
                              {item.promotion_price}{" "}
                              <span className="fs-6">ر.س </span>
                            </p>
                          </div>
                        </div>
                        <button
                          onClick={() => remove(item)}
                          className="close-icon btn "
                        >
                          <IoIosClose />
                        </button>
                      </div>
                    </Fragment>
                  );
                })}
              {itemsfree &&
                itemsfree.map((item) => {
                  return (
                    <Fragment key={item.id}>
                      <div
                        key={item.id}
                        className="d-flex justify-content-between  mt-2    align-items-center"
                      >
                        <div className="d-flex ml-2">
                          <div className="position-relative  img-shoping">
                            <div className="div-reting  position-absolute ">
                              {" "}
                              4.5{" "}
                              <span className="star-active">
                                <AiFillStar />
                              </span>
                            </div>
                            <img
                              className=" border border-1 ml-2"
                              width={120}
                              height={120}
                              src={item.image}
                              alt=""
                            />
                          </div>
                          <div className="mx-2">
                            <h5 className="fs-6">
                              {item[`product_${lang}_name`]}
                            </h5>
                            <p className="mb-0">
                              الكمية:
                              {item.quantity}
                            </p>
                            <p className="fs-5 main-color">(مجانا)</p>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EnterAddres;
