import React, { useEffect, useState } from "react";
import "./style.css";
import singin from "../../asset/images/الكرتون مع المياه.webp";
import { RiLockPasswordLine } from "react-icons/ri";
import MaskGroup from "../../asset/images/الكرتون مع المياه شفاف.webp"
import { useDispatch, useSelector } from "react-redux"; 
import {  nempass } from "../../services/action/action";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const NemPass = () => {
    const navigate = useNavigate();
    const [code, setcode] = useState();
    const [password, setpassword] = useState();
    const dispach= useDispatch()
    const hendleing = event =>{
        event.preventDefault()

       const  phone  =JSON.parse( sessionStorage.getItem("phoneNumber"))
        const data ={ phone:phone.phone ,new_password:password,code:code }
        dispach(nempass(data))
    }
    const success = useSelector(state => state.newpass )
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
   
    if(success && success.success == 1){
        navigate("/signIn")
    }
const path =window.location.href

    return (
        <>
          <Helmet>
                <title>rest new password</title>
                <meta
                    name="description"
                    content="Write a new password for your account "
                />
                <link rel="canonical" href={path} />
            </Helmet>
            <h1 className="visually-hidden">Reset your account password</h1>
            <section className="sigIn-section " >
                <div className="row div-content ">
                    <div className="col col-12 col-md-6">
                        <h2 className="main-color"> استرجاع كلمة المرور</h2>
                        <p className="color-text-singin mb-3">من فضلك ادخل الكود الذي وصلك وادخل  كلمة السر الجديدة  </p>
                          { success && success.success == 0  && ( <div className="alert alert-danger" role="alert">
                            Code not correct
                        </div>)}
                      <form onSubmit={hendleing}>
                      <div className="d-flex mt-3 mb-3 position-relative">
                            <input required    onChange={event => setcode(event.target.value)}  className="form-control pt-3 pb-3" type="text" placeholder=" ادخل الكود" />
                        </div>
                        <div className="d-flex mt-3 position-relative">
                            <span className="icon-pass">
                                <RiLockPasswordLine />
                            </span>
                            <input required    onChange={event => setpassword(event.target.value)}  className="form-control pt-2 pb-2" type="password" placeholder="   كلمة المرور الجديدة" />
                        </div>
                        <button disabled={ code &&  code.length >= 2 && password && password.length >= 4? false : true} type="submit" className="w-100 btn btn-login "> ارسال</button>
                      </form>
               
                  
                    </div>
                    <div className="col col-12 col-md-6">
                        <img className="w-100" src={singin} alt="" />
                        <img className="w-100" src={MaskGroup} alt="" />

                    </div>

                </div>
            </section>

        </>
    )
};
export default NemPass;