import React, { useEffect } from "react";
import "./style.css";
import { BsCheckLg } from "react-icons/bs";
import { FiFacebook } from "react-icons/fi";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";
import FollowStep from "./FollowStep";
import { useDispatch, useSelector } from "react-redux";
import {
  detailesOrder,
  ecommercePayment,
  trackingOrder,
} from "../../services/action/action";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import queryString from "query-string";
import DeliveryConfirmation from "./DeliveryConfirmation";
import {
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
const CompletPay = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const usertoken = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    dispatch(trackingOrder(param.id, usertoken.data.token));
    window.scrollTo(0, 0);
  }, []);

  const data = useSelector((state) => state.tracking);
  const urlPage = window.location.href;
  const urlPagetest = window.location;
  const datapayment = queryString.parse(window.location.search);
  useEffect(() => {
    if (
      data &&
      data.data &&
      datapayment &&
      datapayment.message &&
      datapayment.message == "APPROVED" &&
      datapayment.status == "paid"
    ) {
      dispatch(
        ecommercePayment(
          data.data.order.id,
          usertoken.data.token,
          datapayment.id
        )
      );
      dispatch(detailesOrder(data.data.order.reference, usertoken.data.token));
    }
  }, [data]);
  const datar = useSelector((state) => state.ecommercePayment);
  if (
    datar &&
    datar.status == 1 &&
    datapayment &&
    datapayment.message &&
    datapayment.message == "APPROVED"
  ) {
    window.location.href = urlPagetest.href.split("?id=")[0];
  }
  const path = window.location.href;

  return (
    <>
      <Helmet>
        <title>Order tracking </title>
        <meta
          name="description"
          content="Tracking the order from the first stage to the final stage until confirming receipt and completing the delivery process successfully"
        />
        <link rel="canonical" href={path} />
      </Helmet>

      <h1 className="visually-hidden">
        Details of your order that you purchased from wav website
      </h1>
      {datapayment &&
      datapayment.message &&
      datapayment.message != "APPROVED" &&
      datapayment.status &&
      datapayment.status != "paid" ? (
        <div className="notpay">
          <h2 className="text-center"> ! {datapayment.message} </h2>
          <p>{datapayment.status}</p>
          <Link to={`/Pyment/${param.id}`}>
            {" "}
            <button className="btn add-card w-50 mt-3">
              تاكد من وسيلة الدفع واعد المحاولة
            </button>{" "}
          </Link>
        </div>
      ) : (
        <section className="container completBuy mt-5">
          <div className="row">
            <div className="col col-12 col-md-12 text-center p-3 div-num-order">
              <span className="icon">
                <BsCheckLg />
              </span>
              <p>تم حجز الطلب بنجاح</p>
              <p>رقم الطلب</p>
              <p>
                #{data.data && data.data.order && data.data.order.reference}
              </p>
              {data && data.status && (
                <div className=" mt-4 mt-md-0">
                  <div
                    className=" d-flex
    align-items-center
    justify-content-center
    flex-column"
                  >
                    <div className="d-flex justify-content-between">
                      <h4>شارك طلبك </h4>
                    </div>
                    <div className="d-flex">
                      <div className="d-flex flex-column data-orders mr-3">
                        <div>
                          <TwitterShareButton url={urlPage} hashtag={"#wav"}>
                            <button className="twi-icon btn">
                              {" "}
                              <AiOutlineTwitter />
                            </button>
                          </TwitterShareButton>
                          <WhatsappShareButton url={urlPage} hashtag={"#wav"}>
                            <button className="goog-icon twi-icon btn">
                              <AiOutlineWhatsApp />
                            </button>
                          </WhatsappShareButton>
                          <FacebookShareButton url={urlPage} hashtag={"#wav"}>
                            <button className="face-icon btn">
                              <FiFacebook />
                            </button>
                          </FacebookShareButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col col-12 p-3 copy-link-order mt-4">
              <h4>تتبع الطلب</h4>
              <p>من فضلك تتبع طلبك من خلال الرابط الآتي</p>
              <p className="main-color">
                {urlPage.substr(0, urlPage.indexOf("?id"))}
              </p>
              <button
                onClick={() =>
                  navigator.clipboard.writeText(
                    urlPage.indexOf("?id") != -1
                      ? urlPage.substr(0, urlPage.indexOf("?id"))
                      : urlPage
                  )
                }
                className="btn add-card p-2"
              >
                نسخ الرابط
              </button>
            </div>
            {data && data.status && <FollowStep data={data} />}
            {/* {data && data.status && data.data.order.status >= 5 && <DeliveryConfirmation />} */}
          </div>
        </section>
      )}
    </>
  );
};

export default CompletPay;
