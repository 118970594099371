import React, { useEffect } from "react";
import "./style.css";
import filters from "../../asset/images/img-filters.webp";
import upport from "../../asset/images/icon-support.webp";
import water from "../../asset/images/water-icon.webp";
import shoping from "../../asset/images/shoping-car.webp";
import { Helmet } from "react-helmet-async";

function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const path = window.location.href;

  return (
    <>
      <Helmet>
        <title>about me</title>
        <meta
          name="description"
          content="Wav  Company Products, customer opinions about us, and information about the company's activities and services"
        />
        <link rel="canonical" href={path} />
      </Helmet>
      <div id="about" className="about-section d-flex align-items-center">
        <div className="container pt-3 pb-3">
          <div className="row conent-about">
            <div className="col col-12 col-lg-6">
              <p className=" p-top ">من نحن</p>
              <h2 className="main-color">
                من أفضل الشركات <br /> في مجال
                <span className="mark-word"> تنقية وتوزيع المياه</span>{" "}
              </h2>
              <h3 className="color-main mt-3">رؤيتنا</h3>
              <p>
                بدأت فكرة مياه واڤ منذ سنوات عديدة مع الطموح والرغبة في جلب
                المياه النقية للجميع.
                <br /> قامت شركة أرغد المحدودة بوضع حجر الأساس لمصنع مياه واڤ
                على أرض تم اختيارها بعناية في مدينة الخرج ومن ثم تخطت جميع
                العقبات من اجل الاستمرار بالحلم والرغبة والطموح لبناء أفضل مصنع
                مياه واڤ في المملكة بتكنولوجيا ومعدات تضمن أفضل معايير الجودة في
                تصنيع المياه المعبأة. <br />
                ولن تتوقف مياه واڤ هنا فحسب بل و تبني أيضًا أسطولًا ضخمًا من
                عربات النقل الكبيرة والصغيرة لخدمة العملاء وتوصيل منتج مياه واڤ
                إلى جميع أنحاء المملكة العربية السعودية وتصل إليك في أقرب نقطة
                بيع أو بضغطة زر عن طريق موقعها الإلكتروني و عن طريق تطبيقاتها
                لأجهزة الايفون و أجهزة الأندرويد يصل طلبك الى منزلك او عملك او
                حتى في مناسباتك
              </p>
            </div>
            <div className="col col-12 col-lg-6 ">
              <div className="row">
                <div className="col col-12 col-sm-6  ">
                  <div>
                    <span className="parent-img">
                      <img
                        width={60}
                        height={60}
                        className="w-100 h-auto"
                        src={filters}
                        alt="Water purification"
                      />
                    </span>
                    <h4 className="main-color"> تنقية المياه </h4>
                    <p>
                      تنقية المياه هي عملية إزالة الملوثات من المياه الخام.
                      والغرض من ذلك هو إنتاج المياه لغرض محدد للاستهلاك البشري
                      لهذا قامت شركة أرغد المحدودة بإنشاء أفضل المعامل لتنقية
                      المياه في مصانع مياه واڤ
                    </p>
                  </div>
                </div>
                <div className="col col-12 col-sm-6  ">
                  <div>
                    <span className="parent-img">
                      <img
                        width={60}
                        height={60}
                        className="w-100 h-auto"
                        src={shoping}
                        alt="Express delivery"
                      />
                    </span>
                    <h4 className="main-color">التوصيل السريع</h4>
                    <p>
                      لدينا اسطول كامل في خدمة عملائنا ونقدم خدمة التوصيل السريع
                      وذلك لخدمتكم بشكل أفضل حيث ان مدة توصيل الطلب لدينا لا
                      تتجاوز 48 ساعة كحد أقصى
                    </p>
                  </div>
                </div>
                <div className="col col-12 col-sm-6  ">
                  <div>
                    <span className="parent-img">
                      <img
                        width={60}
                        height={60}
                        className="w-100 h-auto"
                        src={water}
                        alt="Healthy water"
                      />
                    </span>
                    <h4 className="main-color"> الماء الصحي</h4>
                    <p>
                      {" "}
                      مياه واڤ هي مياه ابار جوفية معبأة ونحن نلتزم بإنتاج المياه
                      بأعلى معاير الجودة والمتوافقة مع ترخيصنا من هيئة الغذاء
                      والدواء{" "}
                    </p>
                  </div>
                </div>
                <div className="col col-12 col-sm-6  ">
                  <div>
                    <span className="parent-img">
                      <img
                        width={60}
                        height={60}
                        className="w-100 h-auto"
                        src={upport}
                        alt="Technical support"
                      />
                    </span>
                    <h4 className="main-color"> الدعم الفني</h4>
                    <p>
                      {" "}
                      في حال وجود أي مشاكل في الموقع الإلكتروني او في تطبيقات
                      الجوال او في احدى طلباتك لدينا بإمكانك التواصل معنا عن
                      طريق رابط الشكاوى او عن طريق الاتصال بالرقم الموحد الخاص
                      بنا 920006158 او عن طريق التواصل معنا في حساباتنا في مواقع
                      التواصل الاجتماعي{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutPage;
