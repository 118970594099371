
const initalValue = {
      districts: [],
      prodects: [],
      details: [],
      custom: [],
      user: [],
      auth: [],
      isLogin: true,
      isLoggedIn: false,
      exit: [],
      cities: [],
      cards:[],
      cardsfree:[],
      address:[],
      discount:[],
      shipping:[],
      newpass:[],
      AddNewOrder:[],
      shippinCost:[],
      addorder:[],
      tracking:[],
      signup:[],
      AddresOrder:[],
      step:0,
      ecommercePayment:[],
      sentsms:[],
      MapLocal:[],
      addfavProduct:[],
      favProductlist:[],
      sendEmail:[],
      presents:[],
      chart:[],
      mosque:[]
     
}
export const casherReducer = (state = initalValue, action) => {
      switch (action.type) {
            case "USER":
                  return { ...state, user: action.user }
            case "auth":
                  return { ...state, auth: action.payload }
            case "isLogin":
                  return { ...state, isLogin: action.isLogin }
            case "EXITPHONE":
                  return { ...state, exit: action.exit };
            case "NEWPASS":
                return { ...state, newpass: action.newpass };
            case "SHIPPING":
                  return { ...state, shippinCost: action.shippinCost };
            case "RESTAPI":
                  return { ...state, restPass: action.restPass };
            case "ALLPRODECTS":
                  return { ...state, prodects: action.prodects }
            case "ALLpresents":
                  return { ...state, presents: action.presents }
            case "DETAILS":
                  return { ...state, details: action.details }
            case "DISTRICTS":
                  return { ...state, districts: action.districts }
            case "ADDITEM":
                  return { ...state, cards: action.cards }
             case "ADDITEMFREE":
                  return { ...state, cardsfree: action.cardsfree }
            case "ADDNEWORDER":
                 return { ...state, AddNewOrder: action.AddNewOrder }
            case "CITIES":
                  return { ...state, cities: action.cities }
           case "ADDRESS":
                  return { ...state, address: action.address }
           case "DISCOUNT":
                 return { ...state, discount: action.discount }
            case "SHIPPING":
                 return { ...state, shipping: action.shipping }
            case "ADDORDER":
                  return { ...state, addorder: action.addorder }
            case "USERORDERS":
                  return { ...state, userOrders: action.userOrders }
             case "DETAILESORDER":
                  return { ...state, detailesOrder: action.detailesOrder }
            case "TRACKING":
                  return { ...state, tracking: action.tracking }
             case "SIGNUP":
                   return { ...state, signup: action.signup }
             case "AddresOrder":
                  return { ...state, AddresOrder: action.AddresOrder }
            case "step":
                   return { ...state, step: action.step }
            case "ecommercePayment":
                   return { ...state, ecommercePayment: action.ecommercePayment }
             case "sentsms":
                  return { ...state, sentsms: action.sentsms }
             case "MapLocal":
                  return { ...state, MapLocal: action.MapLocal }
             case "addfavProduct":
                  return { ...state, addfavProduct: action.addfavProduct }
             case "sendEmail":
                  return { ...state, sendEmail: action.sendEmail }
             case "prodectHomeList":
                   return { ...state, prodectHomeList: action.prodectHomeList }
             case "favProductlist":
                   return { ...state, favProductlist: action.favProductlist }
            case "chart":
                   return { ...state, chart: action.chart }
             case "mosque":
                    return { ...state, mosque: action.mosque }
            default:
                  return state
      }
}