import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";

import EnterAddres from "../../component/enterAddres/enterAddres";

import SelectShipping from "../../Pages/SelectShipping/SelectShipping";
import CompletPay from "../../Pages/CompletPay/CompletPay";
import "./style.css";
import { Helmet } from "react-helmet-async";
import BtnTopAllShoping from "../../component/btnTopAllShoping/BtnTopAllShoping";

const steps = ["الشحن", "الدفع", "تم الحجز"];

const ShippingData = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const path = window.location.href;

  return (
    <>
      <Helmet>
        <title> Payment steps</title>
        <meta
          name="description"
          content="Steps to purchase products from wav company through Visa or cash"
        />
        <link rel="canonical" href={path} />
      </Helmet>
      <h1 className="visually-hidden">
        Steps to purchase products from wav company through Visa or cash{" "}
      </h1>

      <Box container>
        <BtnTopAllShoping />
        <div className="container">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step container key={label} {...stepProps}>
                  <StepLabel container {...labelProps}>
                    {label}{" "}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>

        {activeStep === steps.length - 1 ? (
          <React.Fragment>
            {setActiveStep(3)}

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep == 0 ? (
              <EnterAddres setActiveStep={setActiveStep} />
            ) : (
              <>{activeStep == 1 ? <SelectShipping /> : <CompletPay />}</>
            )}
          </React.Fragment>
        )}
      </Box>
    </>
  );
};

export default ShippingData;
