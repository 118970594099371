import React, { useEffect, useState } from "react";
import "./style.css";
 import { RiLockPasswordLine } from "react-icons/ri";
import twiterIcon from "../../asset/images/twiterIcon.webp";
import faceIcon from "../../asset/images/faceIcon.webp";
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {  useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import CaptchaComp from "../../component/Captcha";

const SigUp = () => {
  const navigate = useNavigate();
  const [codephon, setcodephon] = useState("966");
  const [phone, setphone] = useState("notval");
  const [name, setFirstName] = useState();
  const [password, setpassword] = useState();
  const [Comfpassword, setComfpassword] = useState();
  const [checloc, setcheckloc] = useState("notLOgin");
  const [validmass, setvalidmass] = useState();
  const [Captcha, setCaptcha] = useState();

 
 
  const handleSubmit = (event) => {
    event.preventDefault(); ///

    const code = Math.floor(Math.random() * 100000);
    if (checloc == "User phone exist") {
      // if exit phone
      setvalidmass("User phone exist");
    } else if (Comfpassword !== password) {
      // if user not equall
      setvalidmass("The password is not equal");
    } else if (Comfpassword !== password) {
      // if user not equall
      setvalidmass("The password is not equal");
    } else {
      const data = { name, phone: `+${codephon}${phone}`, password, code };
      sessionStorage.setItem("signupdata", JSON.stringify(data));
      var formdata = new FormData();
      formdata.append("token", "rOSmK5ocqAY9vEkJEB4PqCCXR4O__rbCDeeZK-_BPVs");
      formdata.append("username", "minaezat");
      formdata.append("src", "wav water");
      formdata.append("body", code);
      formdata.append("dests", `+${codephon}${phone}`);
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch("https://api.oursms.com/api-a/msgs", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result && result.accepted && result.accepted == 1) {
            setvalidmass();
            navigate("/ConfirmNumber");
          } else {
            setvalidmass("The number not falid");
          }
        });
    }
    if (validmass) {
      setTimeout(() => {
        setvalidmass();
      }, 10000);
    }
  };
 
  const userLogn = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (userLogn) {
      userLogn.data.uuid && setcheckloc(userLogn);
    }
    
  }, []);

 
  const phoneNumber = useSelector((stats) => stats.exit);
  useEffect(() => {
    setcheckloc(phoneNumber);
  }, [phoneNumber]);

  // to top page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
    return (
    <>
      <Helmet>
        <title>singup</title>
        <meta
          name="description"
          content="Create a new account to be able to buy products and benefit from the services of the wav website  "
        />
        <link rel="canonical" href="/SigUp" />
      </Helmet>
      <h1 className="visually-hidden">Create a new account on wav</h1>
      <section className="sigIn-section">
        <div className=" div-content container">
          <div className="singup-form">
            <h2 className="main-color">انشاء حساب جديد </h2>
            
            <form onSubmit={handleSubmit} >
              {validmass && (
                <div className="alert alert-danger" role="alert">
                  {validmass}
                </div>
              )}
              <div className="row">
                <div className="col col-12 col-sm-6 b">
                  <div className=" mt-3  input-group main-color border border-2 rounded-2 input-phone-dir">
                    <select
                      name="code"
                      className="border-0 main-color  rounded-2"
                      onChange={(even) => setcodephon(even.target.value)}
                    >
                      <option value="966"  >
                        +966
                      </option>
                    </select>

                    <input
                      type="number"
                      placeholder="5********"
                      onChange={(even) => setphone(even.target.value)}
                      className="form-control border-0 pr-0  rounded-2 tel"
                      aria-label="Text input with dropdown button"
                    />
                  </div>
                </div>

                <div className="col col-12 col-sm-6">
                  {" "}
                  <div className="d-flex mt-3 position-relative">
                    <span className="icon-pass">
                      <AiOutlineUser />
                    </span>
                    <input
                      required
                      name="name"
                      onChange={(event) => setFirstName(event.target.value)}
                      className="form-control pt-2 pb-2"
                      type="text"
                      placeholder="الاسم"
                    />
                  </div>
                </div>
                <div className="col  col-12 col-sm-6">
                  {" "}
                  <div className="d-flex mt-3 position-relative">
                    <span className="icon-pass">
                      <RiLockPasswordLine />
                    </span>
                    <input
                      required
                      name="password"
                      onChange={(event) => setpassword(event.target.value)}
                      className="form-control pt-2 pb-2"
                      type="password"
                      placeholder="كلمة المرور"
                    />
                  </div>
                </div>

                <div className="col col-12 col-sm-6">
                  {" "}
                  <div className="d-flex mt-3 position-relative">
                    <span className="icon-pass">
                      <RiLockPasswordLine />
                    </span>
                    <input
                      required
                      onChange={(event) => setComfpassword(event.target.value)}
                      className="form-control pt-2 pb-2"
                      type="password"
                      placeholder="تاكيد كلمة المرور"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                
          
                <CaptchaComp  setCaptcha={setCaptcha}/>
                </div>
              <button
                disabled={
                  password &&
                  password.length >= 4 &&
                  name &&
                  name.length >= 2 &&
                  phone &&
                  phone.length >= 9 &&
                  Comfpassword &&
                  Captcha &&
                  Comfpassword.length >= 4
                    ? false
                    : true
                }
                type="submit"
                className="w-100 btn btn-login "
              >
                {" "}
                انشاء حساب
              </button>
            </form>
            <div className="text-center">
              <p className="sigin-by-media">او انشاء حساب بواسطة</p>
              <div className="sigin-by-media">
                <img src={twiterIcon} alt="" />
                <img src={faceIcon} alt="" />
              </div>
              <div>
                لديك حساب{" "}
                <Link to={"/signIn"} className="linkNewAcount">
                  سجل دخول
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SigUp;
