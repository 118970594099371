import React, { useEffect, useState } from "react";
import "./style.css";
import singin from "../../asset/images/الكرتون مع المياه.webp";
import MaskGroup from "../../asset/images/الكرتون مع المياه شفاف.webp"
import { useDispatch, useSelector } from "react-redux";
import {  addNewUser } from "../../services/action/action";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import CaptchaComp from "../../component/Captcha";
 
const ConfirmNumber = () => {
    const navigate = useNavigate();
    const [code, setcode] = useState();
    const [massge, setmasssge] = useState(null);
    const [Captcha, setCaptcha] = useState();

    const dispach= useDispatch()
    const hendleing = event =>{
        event.preventDefault()
       const  phone  =JSON.parse( sessionStorage.getItem("signupdata"))
       if(phone && phone.code == code){
        const data = { name:phone.name, phone:phone.phone, password :phone.password}
       dispach(addNewUser(data))
 
    if (window.snaptr) {
        // Send a View Content event to the Snapchat servers
        window.snaptr('track', 'SIGN_UP', {
            content_name: data.name,
            content_type: 'signup',
            status: 'success'
        });
      }
       }else{
        setmasssge("The code is incorrect")

       }

    }
    // ///////
    const success = useSelector(state => state.signup )

    useEffect(()=>{
        window.scrollTo(0, 0)
    
    },[])
   
    if(success && success.success == 1){
        navigate("/signIn")
        const data =""
        dispach(addNewUser(data))

    }
    return (
        <>
          <Helmet>
                <title>rest new password</title>
                <meta
                    name="description"
                    content="Write a new password for your account "
                />
                <link rel="canonical" href="/SigUp" />
            </Helmet>
            <h1 className="visually-hidden">Reset your account password</h1>
            <section className="sigIn-section">
                <div className="row div-content">
                    <div className="col col-12 col-md-6">
                        <h2 className="main-color"> تأكيد رقم الجوال</h2>
                        <p className="color-text-singin mb-3"> ادخل رمز التفعيل</p>
                      {massge != null && <div className="alert alert-danger" role="alert">
                      {massge}
                        </div>}
                      <form onSubmit={hendleing}>
                      <div className="d-flex mt-3 mb-3 position-relative">
                            <input required    onChange={(event) => setcode(event.target.value)}  className="form-control pt-3 pb-3" type="text" placeholder=" ادخل الكود" />
                        </div>
                        <div className="mt-3">
          
                        <CaptchaComp setCaptcha={setCaptcha}/>
                        </div>
                        <button disabled={ Captcha && code &&  code.length >= 2? false : true} type="submit" className="w-100 btn btn-login ">تاكيد</button>
                      </form>
               
                  
                    </div>
                    <div className="col col-12 col-md-6">
                        <img className="w-100" src={singin} alt="" />
                        <img className="w-100" src={MaskGroup} alt="" />

                    </div>

                </div>
            </section>

        </>
    )
};
export default ConfirmNumber;