import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { memo } from "react";
import "./style.css";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { FaAccusoft } from "react-icons/fa";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../asset/images/delfould-image (1).webp";

const Card = memo(({ item, addCartrelat, addfavProduct }) => {
  const [cardprodect, setcardprodect] = useState();

  let lang = "ar";
  let price = 0;
  let promotion_price = null;
  let cslcDic = null;
  if (item) {
    
    price =
      item.final_price == item.promotion_price
        ? item.final_price
        : item.promotion_price;
    promotion_price =
      item.final_price > item.promotion_price ? item.final_price : null;
    cslcDic = 
      item.final_price > item.promotion_price
        ? (((item.final_price - item.promotion_price) / item.final_price) * 100).toFixed(2)
        : null;
  }
  const data = useSelector((state) => state.cards);
  useEffect(() => {
    setcardprodect(data);
  }, [data]);
  const ifexit = (id) => {
    if (cardprodect && cardprodect.length >= 1) {
      return cardprodect.some((val) => val.id === id);
    }
  };
  const replaceImage = (error) => {
    //replacement of broken Image
    error.target.src = logo;
  };

  const trackViewContent = (iteme) => {
    // Initialize the Pixel

    if (window.snaptr) {
      // Send a View Content event to the Snapchat servers
      window.snaptr("track", "VIEW_CONTENT", {
        content_name: iteme.product_ar_name,
        content_ids: [iteme.id],
        content_type: "product",
        value: iteme.final_price,
        currency: "SAR",
      });
    }
  };
  return (
    <>
      {item ? (
        <div key={item.id} className="card w-100  my-2 mx-1">
          <div className="reting-div">
            {" "}
            4.5
            <span className="icon">
              {" "}
              <AiFillStar />
            </span>
          </div>
          {cslcDic !== null && (
            <div className="sale-div">
              {" "}
              <div>خصم %{cslcDic}</div>{" "}
            </div>
          )}
          <Link
            className="no-underline"
            to={`/products/${item["id"]}`}
            onClick={() => trackViewContent(item)}
          >
            <div className="imag-card">
              <LazyLoadImage
                src={item && item.image}
                width={600}
                height={400}
                alt={item[`product_${lang}_name`]}
                className="card-img-top"
                onError={replaceImage}
              />
            </div>
          </Link>

          <div className="card-body">
            <Link
              className="no-underline"
              to={`/products/${item["id"]}`}
              onClick={() => trackViewContent(item)}
            >
              <h5 className="card-title main-color">
                {" "}
                {item[`product_${lang}_name`].substring(0, 40)}
              </h5>
              <p className="card-title main-color"> #{item["sku"]}</p>
              <p className="card-text">
                ...{item[`product_${lang}_desc`].substring(0, 20)}
              </p>
              <h4>
                {" "}
                <span className="old-price">{promotion_price}</span>
                <span className="new-price">
                  {" "}
                  {price} <span className="fs-6">ر.س</span>{" "}
                </span>{" "}
              </h4>
            </Link>
            <div className="icon-card">
              <span>
                <FaAccusoft />
              </span>
              <button
                aria-label="addfavProduct"
                onClick={() => addfavProduct(item)}
                className={
                  item.fav_product && item.fav_product !== 0
                    ? " active btn added"
                    : " active btn"
                }
              >
                <AiFillHeart />{" "}
              </button>
              <button
                disabled={item.product_show_in_store == 0}
                aria-label="addCart"
                onClick={() => addCartrelat(item)}
                className={
                  ifexit(item.id) ? " active btn added" : " active btn"
                }
              >
                <AiOutlineShoppingCart />{" "}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
});
export default Card;
