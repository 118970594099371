import React, { useEffect, useState } from "react";
import "./style.css";
import { FaUserAstronaut } from "react-icons/fa";
import { MdOutlineLanguage } from "react-icons/md";
import { BsExclamationCircle } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";

import { CgLogOff } from "react-icons/cg";
import backprofileDrop from "../../asset/images/backprofileDrop.webp";
import { GiBackwardTime } from "react-icons/gi";

import { FaTag } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CartProfile = ({ logout }) => {
  const [userdata, setuserdata] = useState();
  const { t, i18n } = useTranslation();
  const data = JSON.parse(localStorage.getItem("user"));
  const userUpdate = useSelector((state) => state.user);
  useEffect(() => {
    setuserdata(data);
  }, [userUpdate]);

  return (
    <>
      <div className="cart-profile">
        <div className="bacT-derop">
          <img
            className="w-100"
            src={backprofileDrop}
            alt={userdata && userdata.data && userdata.data.user_name}
          />
        </div>
        <div className="img-users  text-center">
          {userdata && userdata.data && userdata.data.image ? (
            <img className="imgUser" src={userdata.data.image} alt="" />
          ) : (
            <span className="imgUser">
              <FaUserCircle />{" "}
            </span>
          )}
          {/* <span className="imgUser"> <FaUserCircle/> </span> */}
          <p className="main-color fs-4">
            {" "}
            {userdata && userdata.data && userdata.data.user_name}{" "}
          </p>
          <p>{userdata && userdata.data && userdata.data.phone} </p>
        </div>

        <ul className="text-end  ">
          <li>
            <Link to={"/MyOrders"} className="dropdown-item">
              {" "}
              <span className="main-color fs-5">
                <GiBackwardTime />
              </span>{" "}
              طلباتي{" "}
            </Link>
          </li>
          <li>
            <Link to={"/Presents"} className="dropdown-item">
              {" "}
              <span className="main-color fs-5">
                <FaTag />
              </span>{" "}
              العروض
            </Link>
          </li>

          <li>
            {i18n.language == "en" && (
              <a
                className="dropdown-item"
                onClick={() => i18n.changeLanguage("ar")}
              >
                {" "}
                <span className="main-color fs-5">
                  <MdOutlineLanguage />{" "}
                </span>
                ar{" "}
              </a>
            )}
            {i18n.language == "ar" && (
              <a
                className="dropdown-item"
                onClick={() => i18n.changeLanguage("en")}
              >
                {" "}
                <span className="main-color fs-5">
                  <MdOutlineLanguage />{" "}
                </span>{" "}
                en
              </a>
            )}
          </li>
          <li>
            <Link className="dropdown-item" to={"/SupportPage"}>
              {" "}
              <span className="main-color fs-5">
                <FaUserAstronaut />
              </span>{" "}
              اتصل بنا
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/ProfilePage">
              {" "}
              <span className="main-color fs-5">
                <FaUserAstronaut />
              </span>
              حسابي
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="/privacy-policy">
              {" "}
              <span className="main-color fs-5">
                <BsExclamationCircle />
              </span>{" "}
              الشروط والأحكام
            </Link>
          </li>
          <li>
            <a className="dropdown-item" onClick={logout}>
              {" "}
              <span className="main-color fs-5">
                <CgLogOff />
              </span>{" "}
              تسجيل الخروج
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default CartProfile;
