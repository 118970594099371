import React from "react";
import "./style.css";

import { Link } from "react-router-dom";
const TotalOrders = ({nextStep,addressOrder}) => {
  const detalisTotal = JSON.parse(sessionStorage.getItem("detailsCartTotal"))
    return (
        <>
            <div className="totale-prodects">
                <h5 className="main-color">ملخص الطلب</h5>
                <div className="d-flex justify-content-between ">
                    <div>تكلفة الطلب:</div>
                    <div>{detalisTotal.price.toFixed(2)} ر.س</div>
                </div>
                <hr />
                <div className="d-flex justify-content-between ">
                    <div> التوصيل:</div>
                    <div>{detalisTotal.shipping} ر.س</div>
               </div>
                <hr />
                <div className="d-flex justify-content-between ">
                    <div> الضريبة:</div>
                    <div>{detalisTotal.tax_amount.toFixed(2)}ر.س</div>
                </div>
                <hr />
                <div className="d-flex justify-content-between ">
                    <div> الاجمالي:</div>
                    <div className="main-color">{detalisTotal.shipping  >= 0  ?  detalisTotal.total +  detalisTotal.shipping :detalisTotal.total } ر.س</div>
                </div>
                <Link ><button onClick={() =>{nextStep(); addressOrder && addressOrder()} } className="btn add-card w-100 mt-1 mb-1">الدفع</button></Link>
                
            </div>
        </>
    )
};
export default TotalOrders;