import React, { useEffect, useState } from "react";
import "./style.css";
import singin from "../../asset/images/الكرتون مع المياه.webp";
import MaskGroup from "../../asset/images/الكرتون مع المياه شفاف.webp";
import { useNavigate } from "react-router-dom";
import { restPass } from "../../services/action/action";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import CaptchaComp from "../../component/Captcha";

const ForgectPass = () => {
  const navigate = useNavigate();
  const [codephon, setcodephon] = useState("966");
  const [Captcha, setCaptcha] = useState();

  const [phone, setphone] = useState(null);
  const dispach = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hendaling = (event) => {
    event.preventDefault();
    const dat = { phone: `+${codephon}${phone}` };
    dispach(restPass(dat));
  };

  const data = useSelector((state) => state.restPass);
  if (data && data.success == 1) {
    const dat = { phone: `+${codephon}${phone}` };

    sessionStorage.setItem("phoneNumber", JSON.stringify(dat));

    navigate("./NemPass");
  }
  const path = window.location.href;

  return (
    <>
      <Helmet>
        <title>rest password</title>
        <meta
          name="description"
          content="The password recovery page for your on  wav account "
        />
        <link rel="canonical" href={path} />
      </Helmet>
      <h1 className="visually-hidden">Reset your account password</h1>
      <section className="sigIn-section ">
        <div className="container">
          <div className="row div-content ">
            <div className="col col-12 col-md-6">
              <h2 className="main-color"> استرجاع كلمة المرور</h2>
              <p className="color-text-singin mb-3">
                أدخل رقم الجوال المرتبط بحسابك وسنرسل رسالة خاصة لك لإعادة تعيين
                كلمة المرور الخاصة بك
              </p>
              <form onSubmit={hendaling}>
                {data && data.success == 0 && (
                  <div className="alert alert-danger" role="alert">
                    {data.msg}
                  </div>
                )}
                  <div className=" mt-3  input-group main-color border border-2 rounded-2 input-phone-dir">
                    <select
                      name="code"
                      className="border-0 main-color  rounded-2"
                      onChange={(even) => setcodephon(even.target.value)}
                    >
                      <option value="966" >
                        +966
                      </option>
                    </select>

                    <input
                      type="number"
                      placeholder="5********"
                      onChange={(even) => setphone(even.target.value)}
                      className="form-control border-0 pr-0  rounded-2 tel"
                      aria-label="Text input with dropdown button"
                    />
                  </div>
                   <div className="mt-3">
          
                    <CaptchaComp setCaptcha={setCaptcha}/>
                  </div>
                <button
                  disabled={Captcha && phone && phone.length >= 9 ? false : true}
                  className="w-100 btn btn-login "
                >
                  {" "}
                  ارسال
                </button>
              </form>
              <div className="text-center"></div>
            </div>

            <div className="col col-12 col-md-6">
              <img className="w-100" src={singin} alt="" />
              <img className="w-100" src={MaskGroup} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ForgectPass;
