import React, { useEffect, useState, lazy, useCallback } from "react";

import CarouselCompo from "../../component/sliders/carsol/Carousel";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";

import { prodectHomeListfav, prodectHomeList } from "../../services/action/action";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { FaAccusoft } from "react-icons/fa";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import SliderItem from "../../component/sliders/slider/slider";
import SiderBanner from "../../component/sliders/sliderBanner/SiderBanner";
import { Suspense } from "react";
import { AddCartHooks } from "../../component/CustomHooks/AddCartHooks";
import { AddFavHook } from "../../component/CustomHooks/AddFavHook"
const FormSupport = lazy(() => import("../../component/formSupport/FormSupport"));


function HomePage() {
  const [carts, setcarts] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);

  const user = JSON.parse(localStorage.getItem("user"));
  const faVchange = useSelector((state) => state.addfavProduct);
  const getprodectHomeLis = useCallback(
    useSelector((state) => state.prodectHomeList)
  );
  const cart = useSelector((state) => state.cards);
  const favProductlist = useSelector((state) => state.favProductlist);
  const addorder = useSelector((state) => state.addorder);
  const path = window.location;
  const refloc =
    addorder && addorder.data
      ? addorder.data.reference
      : sessionStorage.getItem("referenceOrder");
  let lang = "ar";
  let total = 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // func to get scroll size
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  // check if payment complet or no
  if (path.href.indexOf("message=") > -1) {
    navigate(`CompletPay/${refloc}`);
  }
  //  useEffect hook work after relod page
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.snaptr("rest");
    };
  }, []);
  // work if change in faford
  useEffect(() => {
    if (user && user.data && user.data.token) {
      dispatch(prodectHomeListfav(user.data.token, "?show_type=homepage"));
    } else {
      dispatch(prodectHomeList("?show_type=homepage"));
    }
  }, [faVchange]);
  useEffect(() => {
    setcarts(cart);
  }, [cart]);
  if (carts) {
    const initialValue = 0;

    total = carts.reduce(
      (accumulator, current) => accumulator + current.quantity,
      initialValue
    );
  }
  const [addCart, isShowingAlert, contentpop] = AddCartHooks();

  // // to scroll top btn
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const [addfavProduct] = AddFavHook();

  return (
    <>
      <Helmet>
        <title>مياه واڤ | WAV WATER </title>
        <meta
          name="description"
          content="Wav  Company Products, customer opinions about us, and information about the company's activities and services"
        />
        <link rel="canonical" href={path.href} />
      </Helmet>
      <button
        type="button"
        aria-label="addCartfree"
        className="btn d-hidden"
        id="addCartfree"
        data-bs-toggle="modal"
        data-bs-target="#freeprodect2"
      ></button>
      <div
        className="modal fade "
        id="freeprodect2"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="p-3 text_second_color fs-6  text-center">
                {contentpop}
              </div>
            </div>
            <div className="text-center">
              <button
                aria-label="Close"
                type="button"
                className="btn btn_pop_massge "
                data-bs-dismiss="modal"
              >
                استمرار الطلب
              </button>
            </div>
          </div>
        </div>
      </div>
      <h1 className="visually-hidden">
        wav Water Production and distribution company
      </h1>

      <CarouselCompo listProdects={getprodectHomeLis} />

      {scrollPosition > 170 && (
        <div className="icons-left">
          <span className="span-icon">
            <FaAccusoft />
            <span>0</span>
          </span>
          <Link to={"/FavlistProdects"}>
            {" "}
            <span className="span-icon">
              <AiFillHeart />
              <span>
                {" "}
                {favProductlist &&
                favProductlist.data &&
                favProductlist.data != "No Items"
                  ? favProductlist.data.length
                  : "0"}
              </span>
            </span>
          </Link>
          <button
            className="btn btn-car-icon "
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasScrolling"
            aria-controls="offcanvasScrolling"
          >
            <span className="span-icon">
              <AiOutlineShoppingCart /> <span>{total && total}</span>
            </span>
          </button>
        </div>
      )}
      {scrollPosition > 667 && (
        <button className="btn btn-toTop" onClick={scrollTop}>
          {" "}
          <AiOutlineArrowUp />
        </button>
      )}

      <SliderItem
        isShowingAlert={isShowingAlert}
        lang={lang}
        addCart={addCart}
        addfavProduct={addfavProduct}
        listProdects={getprodectHomeLis}
      />
      <Suspense>
        <SiderBanner listProdects={getprodectHomeLis} />
      </Suspense>
      <section className="form-secton">
        <Suspense>
          <FormSupport title={" للرد علي استفساراتكم"} type={"enquiry"} />
        </Suspense>
      </section>
    </>
  );
}

export default HomePage;
