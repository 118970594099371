import { useDispatch } from "react-redux";
import { addfavProductItem, deletefavProductItem } from "../../services/action/action";
import { useNavigate } from 'react-router-dom';
import { useCallback } from "react";

export const AddFavHook =() =>{
    const dispatch = useDispatch()
    const  user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    const addfavProduct= useCallback( (prodect)=>{
      if(user && user.data &&user.data.token  ){
        if (prodect && prodect.fav_product !== 0) {
          dispatch(deletefavProductItem({product_id:prodect.id},user.data.token ))
  
        }else{
          dispatch(addfavProductItem({product_id:prodect.id},user.data.token ))
  
        }
      }else{
        navigate("/signIn")
   
      }
      
     },[])
 
     return [addfavProduct]
}