import React, { useEffect, useState } from "react";
import "./style.css";
import singin from "../../asset/images/الكرتون مع المياه.webp";
  import { RiLockPasswordLine } from "react-icons/ri";
import MaskGroup from "../../asset/images/الكرتون مع المياه شفاف.webp";
import { Helmet } from "react-helmet-async";

import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../services/action/action";

import { useNavigate } from "react-router-dom";
import CaptchaComp from "../../component/Captcha";

const SigIn = () => {
  const navigate = useNavigate();

  const [phone, setphone] = useState(null);
  const [password, setpassword] = useState(null);
  const [codephon, setcodephon] = useState("966");
  const [Captcha, setCaptcha] = useState();
  const dispach = useDispatch();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
   useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const hendleing = (event) => {
    event.preventDefault();
    const data = {
      phone: `+${codephon}${phone}`,
      password: password.target.value,
    };
    dispach(login(data));
  };
  const userLogn = JSON.parse(localStorage.getItem("user"));
  //  users.status != 0

  if (userLogn && userLogn.status !== 0) {
    navigate(from);
  }
  const data = useSelector((state) => state.user);
  const path = window.location.href;
   return (
    <>
      <Helmet>
        <title>login page</title>
        <meta
          name="description"
          content="Log in to  wav website to buy wav factory products   "
        />
        <link rel="canonical" href={path} />
      </Helmet>
      <h1 className="visually-hidden">Log in to your acount on wav</h1>
      <section className="sigIn-section  ">
        <div className="container div-content  ">
          <div className="row  ">
            <div className="col col-12 col-md-6">
              <h2 className="main-color">تسجيل الدخول</h2>
             
              {data && data.status == 0 && (
                <div className="alert alert-danger" role="alert">
                  {data.Massage || data.data.Massage}
                </div>
              )}
              <form onSubmit={hendleing} className="mt-5">
                <div className="input-group main-color border border-2 rounded-2 input-phone-dir">
                  <select
                    name="code"
                  
                    className="border-0 main-color  rounded-2"
                    onChange={(even) => setcodephon(even.target.value)}
                  >
                    <option value="966" >
                      +966
                    </option>
                  </select>
                  
                  <input
                    type="number"
                    placeholder="5********"
                    onChange={(even) => setphone(even.target.value)}
                    className="form-control border-0 pr-0  rounded-2 tel"
                    aria-label="Text input with dropdown button"
                  />
                </div>
                <div className="d-flex mt-3 position-relative">
                  <span className="icon-pass">
                    <RiLockPasswordLine />
                  </span>
                  <input
                    required
                    onChange={(event) => setpassword(event)}
                    className="form-control pt-2 pb-2"
                    type="password"
                    placeholder="كلمة المرور"
                  />
                </div> 
                <div className="mt-3">
          
                <CaptchaComp setCaptcha={setCaptcha}/>
                </div>
   
                <Link to={"/ForgectPass"}>
                  {" "}
                  <div className="forgect-pass">نسيت كلمة المرور؟</div>
                </Link>
                <button
                  disabled={
                    phone &&
                     phone.length >= 9 &&
                    password &&
                    Captcha &&
                    password.target.value.length >= 4
                      ? false
                      : true
                  }
                  type="submit"
                  className="w-100 btn btn-login "
                >
                  تسجيل الدخول
                </button>
              </form>
              <div className="text-center">
            
                <div>
                  ليس لديك حساب؟{" "}
                  <Link to={"/SigUp"} className="linkNewAcount">
                    سجل الآن
                  </Link>{" "}
                </div>
                <p className="color-text-singin">
                  {" "}
                  بالدخول للحساب، أنت موافق على
                  <Link className="link-none" to="/Conditions">
                    {" "}
                    الشروط والأحكام
                  </Link>{" "}
                </p>
              </div>
            </div>
            <div className="col col-12 col-md-6">
              <img className="w-100" src={singin} alt="" />
              <img className="w-100" src={MaskGroup} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SigIn;
