import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  AddCart,
  AddCartfree,
  AddNewOrder,
  shippinCost,
} from "../../services/action/action";
import { Helmet } from "react-helmet-async";
import BtnTopAllShoping from "../../component/btnTopAllShoping/BtnTopAllShoping";
import AddREmoveItem from "../../component/addREmoveItem/addREmoveItem";

const ShopingCarPage = () => {
  const [cart, setcart] = useState("");
  const [contentpop, setcontentpop] = useState("");
  const [code, setcode] = useState("");
  const [comment, setcomment] = useState("");
  const [delivey_date, sedelivey_date] = useState(null);
  const [valtion, setvaltion] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {}, [contentpop]);
  let lang = "ar";
  const carShoping = useSelector((state) => state.cards);
  const dispatch = useDispatch();
  const itemsfree = JSON.parse(localStorage.getItem("itemsfree"));
  useEffect(() => {
    setcart(carShoping);
  }, [carShoping]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /////////   details order
  const initialValue = 0;
  var total = 0;
  var tax_amount = 0;
  var price = 0;
  var qyt = 0;
  if (cart && cart.length > 0) {
    total = cart.reduce(
      (accumulator, current) =>
        accumulator + Number(current.promotion_price * Number(current.quantity)),
      initialValue
    );
    tax_amount = cart.reduce(
      (accumulator, current) =>
        Number(accumulator) +
        Number(current.promotion_price / ((100 / current.tax_amount) + 1)) * current.quantity,       
      initialValue
    )
    price = cart.reduce(
      (accumulator, current) => accumulator + (current.promotion_price - (current.promotion_price / ((100 / current.tax_amount) + 1))) * current.quantity,
      initialValue
    );
    qyt = cart.reduce(
      (accumulator, current) => accumulator + current.quantity,
      initialValue
    );
  }

  useEffect(() => {
    if (total > 0) {
      dispatch(shippinCost(total));
    }
  }, [total]);
  const shipping = useSelector((state) => state.shippinCost);

  const remove = (iteme) => {
    const filtered = carShoping.filter((item) => item.id !== iteme.id);
    const filteredfree = itemsfree.filter((item) => item.id !== iteme.id);
    dispatch(AddCart(filtered));
    dispatch(AddCartfree(filteredfree));
  };
  const allremove = () => {
    dispatch(AddCart([]));
    dispatch(AddCartfree([]));
  };

  // ///////////////////////

  //    add new order her
  const addOrder = () => {
    if (delivey_date == null) {
      setvaltion(true);
    } else {
      sessionStorage.setItem(
        "detailsCartTotal",
        JSON.stringify({
          total: total,
          tax_amount: tax_amount,
          price: price,
          shipping: shipping.shipping,
        })
      );
      const data = [{ code, delivey_date, comment, qyt, total }];

      dispatch(AddNewOrder(data));
      navigate("/ShippingData");
    }
  };

  // ///////////////////

  var today = new Date().toISOString().split("T")[0];
  const path = window.location.href;

  return (
    <>
      <div
        className="modal fade"
        id="freeprodect"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="p-3 text_second_color fs-6 text-center">
                {contentpop}
              </div>
            </div>
            <div className="text-center">
              <button
                aria-label="Close"
                type="button"
                className="btn btn_pop_massge"
                data-bs-dismiss="modal"
              >
                استمرار الطلب
              </button>
            </div>
          </div>
        </div>
      </div>
      <Helmet>
        <title> my Card</title>
        <meta
          name="description"
          content="The page showing the products that you want to buy from the wav website"
        />
        <link rel="canonical" href={path} />
      </Helmet>
      <h1 className="visually-hidden">
        View the products you want to buy from wav website{" "}
      </h1>

      <BtnTopAllShoping />
      <section className="shopingCarPage container">
        <div className="d-flex justify-content-between ">
          <h2 className="main-color">سلة المشتريات</h2>
          <button onClick={allremove} className="btn btn-delete">
            {" "}
            <RiDeleteBin6Line />
            <span>حذف الكل</span>
          </button>
        </div>
        <div className="overflow-auto">
          <table className="table mt-3">
            <thead>
              <tr className="header-tabel">
                <th scope="col" style={{ width: "60px" }}></th>
                <th scope="col">المنتج</th>
                <th scope="col">السعر </th>
                <th className="text-center" scope="col ">
                  الكمية
                </th>
                <th scope="col"> الضريبة </th>
                <th scope="col">الاجمالي</th>
              </tr>
            </thead>
            <tbody>
              {cart
                ? cart.map((item, index) => {
                    return (
                      <Fragment key={item.id}>
                        <tr key={item.id}>
                          <th scope="row">
                            <button
                              onClick={() => remove(item)}
                              className="delete-icon btn mt-3"
                            >
                              <AiOutlineClose />
                            </button>
                          </th>
                          <td>
                            <div className="d-flex   align-items-center">
                              <img
                                className="border border-2 rounded-2 mx-2"
                                src={item.image}
                                alt=""
                              />

                              <h6 className="name-prodect mt-3">
                                {" "}
                                {item[`product_${lang}_name`]}
                              </h6>
                            </div>
                          </td>
                          <td>
                            <div className="main-color mt-3">
                              { (item.promotion_price - (item.promotion_price / ((100 / item.tax_amount) + 1))).toFixed(2)} ر.س
                            </div>
                          </td>

                          <td>
                            <div className="addQunddiv  mt-3">
                              <AddREmoveItem
                                setcontentpop={setcontentpop}
                                carShoping={item}
                                indexP={index}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="main-color mt-3">
                              {(
                                (item.promotion_price / ((100 / item.tax_amount) + 1))*
                                item.quantity
                              ).toFixed(2)}{" "}
                              ر.س
                            </div>
                          </td>
                          <td>
                            <div className="main-color mt-3">
                              {item.promotion_price * item.quantity} ر.س
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })
                : null}
              {itemsfree
                ? itemsfree.map((item) => {
                    return (
                      <Fragment key={item.id}>
                        <tr key={item.id + 1}>
                          <th scope="row"></th>
                          <td>
                            <div className="d-flex align-items-center ">
                              <img
                                className="border border-2 rounded-2"
                                src={item.image}
                                alt=""
                              />

                              <h6 className="name-prodect mt-3">
                                {" "}
                                {item[`product_${lang}_name`]}
                              </h6>
                            </div>
                          </td>
                          <td>
                            <div className="main-color mt-3">(مجانا)</div>
                          </td>

                          <td>
                            <div className="addQunddiv mt-3">
                              <strong>{item.quantity} </strong>
                            </div>
                          </td>
                          <td>
                            <div className="main-color mt-3">(مجانا)</div>
                          </td>
                          <td>
                            <div className="main-color mt-3">(مجانا)</div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        <h5>لديك كوبون خصم؟</h5>
        <div className="d-flex">
          <input
            onChange={(event) => setcode(event.target.value)}
            type="text"
            className="form-control w-100 ml-4 p-2"
            id="inputPassword1"
            placeholder="أدخل كوبون الخصم"
          />
        </div>

        <h5 className="mt-4">تعليمات خاصة</h5>
        <div className="">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            يرجي ادخال أي تعليمات خاصة بالطلب
          </label>
          <textarea
            value={comment}
            onChange={(event) => setcomment(event.target.value)}
            className={"form-control"}
            placeholder="مثال : الطلب يصل قبل المغرب"
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
        <h5 className="mt-3">تاريخ التوصيل </h5>
        <input
          min={today}
          onChange={(event) => sedelivey_date(event.target.value)}
          type="date"
          className={
            valtion && delivey_date == null
              ? "form-control date-input w-100 ml-4 p-2 mt-2 mb-2 errovalid"
              : "form-control form-control date-input w-100 ml-4 p-2 mt-2 mb-2"
          }
          id="birthday"
          name="birthday"
          placeholder="التاريخ والوقت"
        />
        <div className="totale-prodects">
          <h5 className="main-color">ملخص الطلب</h5>
          <div className="d-flex justify-content-between ">
            <div>تكلفة الطلب:</div>
            <div>{price.toFixed(2)} ر.س</div>
          </div>
          <hr />
          <div className="d-flex justify-content-between ">
            <div> التوصيل:</div>
            <div>{shipping.shipping} ر.س</div>
          </div>
          <hr />
          <div className="d-flex justify-content-between ">
            <div> الضريبة:</div>
            <div>{tax_amount.toFixed(2)} ر.س</div>
          </div>
          <hr />
          <div className="d-flex justify-content-between ">
            <div> الاجمالي:</div>
            <div className="main-color">
              {shipping.shipping >= 0
                ? (Number(total) + Number(shipping.shipping)).toFixed(2)
                : Number(total).toFixed(2)}{" "}
              ر.س
            </div>
          </div>
          <button onClick={addOrder} className="btn add-card w-100 mt-1 mb-1">
            الدفع
          </button>
        </div>
      </section>
    </>
  );
};
export default ShopingCarPage;
