import React, { useEffect, useState } from "react";
import DetailsSlider from "./detailsSlider/detailsSlider";
import "./style.css";
import { BiShare } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { BsCartPlus } from "react-icons/bs";
import BeatLoader from "react-spinners/BeatLoader";

import { useSelector } from "react-redux";
import {
  LinkedinShareButton,
  LineIcon,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { useParams } from "react-router";
import AddREmoveItem from "../addREmoveItem/addREmoveItem";
import { memo } from "react";

const DetailsProdect = memo(
  ({ Retingsection, detailes, addCart, addfavProduct, setcontentpop }) => {
    const [prodectd, setprodect] = useState(false);
    const [indexP, setindexP] = useState();
    const [carShop, secarShop] = useState([]);
    const carShoping = useSelector((state) => state.cards);

    const urlPage = window.location.href;
    const lang = "ar";
    const param = useParams();
    useEffect(() => {
      secarShop(carShoping);
    }, [carShoping]);
    useEffect(() => {
      if (carShop) {
        const tedt = carShop.some((element) => {
          if (element.id == param.id) {
            return element;
          }
        });
        setprodect(tedt);
      }
      if (detailes) {
      }

      if (detailes && carShop) {
        let findindex = carShop.findIndex((obj) => obj.id === detailes[0].id);
        setindexP(findindex);
      }
    }, [indexP, carShop, detailes]);

    let price = 0;
    let promotion_price = null;
    if (detailes) {
      price =
        detailes[0].final_price == detailes[0].promotion_price
          ? detailes[0].final_price
          : detailes[0].promotion_price;
      promotion_price =
        detailes[0].final_price > detailes[0].promotion_price
          ? detailes[0].final_price
          : null;
    }

    return (
      <>
        {detailes ? (
          <section className="detailsprodect container">
            <div className="row pt-4 pb-4">
              <div className="col col-12 col-md-6 p-2">
                <DetailsSlider detailes={detailes} />
              </div>
              <div className="col col-12 col-md-6 detailes-card">
                <div className="align-items-center scend-color d-flex justify-content-between">
                  <div className="fs-5">
                    {" "}
                    <span>!</span> عرض خاص
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary rounded-pill p-3"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <span>
                        <BiShare />
                      </span>{" "}
                      مشاركة
                    </button>
                  </div>

                  <div
                    className="modal fade"
                    id="staticBackdrop"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">شارك مع الاخرين</div>
                        <div className="modal-header">
                          <FacebookShareButton url={urlPage} hashtag={"#wav"}>
                            <FacebookIcon size={40} round={true} />
                          </FacebookShareButton>
                          <LinkedinShareButton url={urlPage} hashtag={"#wav"}>
                            <LineIcon size={40} round={true} />
                          </LinkedinShareButton>
                          <TwitterShareButton url={urlPage} hashtag={"#wav"}>
                            <TwitterIcon size={40} round={true} />
                          </TwitterShareButton>
                          <TelegramShareButton url={urlPage} hashtag={"#wav"}>
                            <TelegramIcon size={40} round={true} />
                          </TelegramShareButton>
                          <WhatsappShareButton url={urlPage} hashtag={"#wav"}>
                            <WhatsappIcon size={40} round={true} />
                          </WhatsappShareButton>
                        </div>
                        <div>
                          <button
                            aria-label="Close"
                            type="button"
                            className="btn btn-secondary w-100"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="fs-4">{detailes[0][`product_${lang}_name`]}</h2>
                <p className="card-title main-color"> #{detailes[0]["sku"]}</p>
                <p>{detailes[0][`product_${lang}_desc`]}</p>
                <div className="">
                  <span className="old-price fs-3"> {promotion_price}</span>{" "}
                  <span className="new-price fs-2">
                    {" "}
                    {price} <span className="d-inline-block fs-6">ر.س</span>
                  </span>{" "}
                  <span className="star-active">
                    {" "}
                    <AiFillStar />{" "}
                  </span>
                  4.5 ( 3
                  <button onClick={Retingsection} className="btn ">
                    <u> تقييم من العملاء </u>
                  </button>{" "}
                  ){" "}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: detailes[0][`product_${lang}_content`],
                  }}
                />

                <div className="row ">
                  <div className="col-2 ">
                    <button
                      onClick={() => addfavProduct(detailes[0])}
                      className={
                        detailes[0].fav_product == 1
                          ? "btn rounded-circle fs-3 btn-heart added"
                          : "btn rounded-circle fs-3 btn-heart"
                      }
                    >
                      <AiFillHeart />{" "}
                    </button>
                  </div>

                  <div className={prodectd ? "col-10 col-sm-5" : "col-10 "}>
                    <button
                      disabled={detailes[0].product_show_in_store == 0}
                      aria-label="addCart"
                      onClick={() => addCart(detailes[0])}
                      className="btn w-100 add-card"
                    >
                      {" "}
                      {prodectd ? " تم الاضافة" : " أضف الي السلة "}{" "}
                      <BsCartPlus />
                    </button>
                  </div>
                  <div className="col-12 mt-2 col-sm-5">
                    {carShoping && carShoping[indexP] && indexP >= 0 && (
                      <div className=" d-flex align-items-center justify-content-center">
                        <AddREmoveItem
                          setcontentpop={setcontentpop}
                          carShoping={carShoping}
                          indexP={indexP}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div className="spinner">
            <BeatLoader color="#36d7b7" margin={8} size={20} />
          </div>
        )}
      </>
    );
  }
);
export default DetailsProdect;
