import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import "./style.css";
import img_caro from "../../../asset/images/توصيل مجاني.webp"
import BeatLoader from "react-spinners/BeatLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { memo } from 'react';


const replaceImage = (error) => {
  error.target.src = img_caro;
}
const CarouselCompo = ({ listProdects }) => {

  return (

    <>
      <div className='carouser-section'>
        {
          listProdects && listProdects.data ? <section>

            <Carousel fade>

              {
                listProdects && listProdects.data && listProdects.data.homeSlider && listProdects.data.homeSlider.map((item) => {

                  return (
                    <Carousel.Item key={item.id}>
                      <LazyLoadImage
                        alt="First slide"
                        className="d-block w-100 img-dynamic h-auto"
                        src={item.image} width={1920} height={650} onError={replaceImage}
                      />

                    </Carousel.Item>
                  )
                })
              }


            </Carousel>
          </section> : <div className='spinner'>
            <BeatLoader color="#36d7b7" margin={8}
              size={20}
            />
          </div>}
      </div>

    </>
  )
}


export default memo(CarouselCompo);
