import React from "react";
import { Carousel } from "react-carousel-minimal";
import "./style.css";
import { AiFillStar } from "react-icons/ai";
import BeatLoader from "react-spinners/BeatLoader";
import { memo } from "react";

const DetailsSlider = memo(({ detailes }) => {
  let cslcDic = null;
  if (detailes) {
    cslcDic =
      detailes[0].final_price > detailes[0].promotion_price
        ? ((detailes[0].final_price - detailes[0].promotion_price) /
            detailes[0].final_price) *
          100
        : null;
  }

  const data = [
    {
      image: detailes[0].image,
    },
  ];

  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <div className="App DetailsSlider">
      <div>
        <div className="position-relative overflow-hidden">
          <div className="reting-div">
            {" "}
            4.5
            <span className="icon">
              {" "}
              <AiFillStar />
            </span>
          </div>
          {cslcDic !== null && (
            <div className="sale-div">
              {" "}
              <div>خصم %{cslcDic}</div>{" "}
            </div>
          )}
          {detailes ? (
            <Carousel
              data={data}
              time={10000}
              slideNumber={false}
              slideNumberStyle={slideNumberStyle}
              captionPosition="top"
              automatic={true}
              dots={false}
              slideImageFit="cover"
              thumbnails={true}
            />
          ) : (
            <div className="spinner">
              <BeatLoader color="#36d7b7" margin={8} size={20} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default DetailsSlider;
