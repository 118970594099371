import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { mosque } from '../../services/action/action';

export default function SelectMosques({ setmosquetyeId }) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(mosque())
    }, [])
    const mosques = useSelector(state => state.mosque)

    const options = [];
    if (mosques && mosques.status) {
        mosques.data.forEach(item => {
            options.push({ value: item, label: item.name })
        });
    }
    return (
        <>
            <div>
                <p>
                    من فضلك حدد المسجد الذي تريد ان يصل الطلب اليه
                </p>

                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="color"
                    options={options}
                    onChange={(event) => event && setmosquetyeId(event.value)}
                />


            </div>
        </>
    )
}
