import React, { useEffect, useState } from "react";
import "./style.css";
import { MdModeEdit } from "react-icons/md";
import Select from "react-select";
import Visa from "../../asset/images/Visa_logo.png.webp";
import Stc from "../../asset/images/Stc_pay.svg.webp";
import MasterCard from "../../asset/images/MasterCard_logo.png.webp";
import Mada from "../../asset/images/1200px-Mada_Logo.svg.webp";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  addAddress,
  AddCart,
  AddCartfree,
  AddNewOrder,
  addOrder,
  addressOrder,
} from "../../services/action/action";

const SelectShipping = () => {
  const [edit, setedit] = useState(true);
  const [editDate, seteditDate] = useState(false);
  const [wayPay, setwayPay] = useState("notSelcted");
  const [detaileAddress, setdetaileAddress] = useState(null);
  const [address_type, seaddress_type] = useState(null);

  const [district, setdistrict] = useState(null);
  const [prodectData, setprodectData] = useState(null);
  const [delivey_date, sedelivey_date] = useState();

  const navigate = useNavigate();

  const dispach = useDispatch();
  let lang = "ar";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data_Addres = JSON.parse(localStorage.getItem("address_order"));
  const detalisTotal = JSON.parse(sessionStorage.getItem("detailsCartTotal"));
  const addresaDetails = useSelector((state) => state.AddresOrder);
  const mosque_data = JSON.parse(sessionStorage.getItem("mosque_data"));

  useEffect(() => {
    if (mosque_data) {
      setprodectData(mosque_data);
      setdistrict(data_Addres.distValLab);
    } else {
      setprodectData(data_Addres);
      seaddress_type(data_Addres.address_type);
      setdistrict(data_Addres.distValLab);
      setdetaileAddress(data_Addres.address);
    }
  }, [addresaDetails]);

  const cards = useSelector((state) => state.cards);
  const checkout = () => {
    const commintuser = JSON.parse(localStorage.getItem("addNewOrder"));
    const user = JSON.parse(localStorage.getItem("user"));
    var dataid = [];
    var dataqu = [];
    var purchaseDataArray = [];
    cards.map((item) => {
      dataid.push(item.id);
      dataqu.push(item.quantity);
      purchaseDataArray.push({
        name: item.product_ar_name,
        content_ids: [item.id],
        content_type: "product",
        value: item.final_price,
        currency: "SAR",
        num_items: item.quantity,
      });
    });
    let data = {
      business_id: user.data.business_id,
      delivey_date: commintuser[0].delivey_date,
      comment: commintuser[0].comment,
      product_id: dataid,
      item_qty: dataqu,
      address: prodectData.address,
      longitude: prodectData.longitude,
      district: prodectData.district,
      latitude: prodectData.latitude,
      payment_method: wayPay,
      discount: commintuser[0].code,
      mosque_id: mosque_data ? mosque_data.id : "",
    };

    if (wayPay !== "notSelcted") {
      dispach(addOrder(data, user.data.token));

      if (window.snaptr) {
        // Send a View Content event to the Snapchat servers
        purchaseDataArray.forEach((purchaseData) => {
          window.snaptr("track", "PURCHASE", {
            content_name: purchaseData.name,
            content_ids: purchaseData.content_ids,
            content_type: purchaseData.content_type,
            value: purchaseData.value,
            currency: purchaseData.currency,
            num_items: purchaseData.num_items,
          });
        });
      }
    }
  };

  const addorder = useSelector((state) => state.addorder, shallowEqual);
  if (addorder && addorder.status) {
    window.gtag("event", "conversion", {
      send_to: "AW-11024898853/bw0cCNuop4YYEKW2iokp",
      transaction_id: addorder.data.reference, // Modify the transaction_id value as needed
    });
    if (wayPay == "visa") {
      dispach(AddCart([]));
      dispach(AddCartfree([]));
      navigate(`/Pyment/${addorder.data.reference}`);
      window.location.reload();
    } else {
      dispach(AddCart([]));
      dispach(AddCartfree([]));
      navigate(`/CompletPay/${addorder.data.reference}`);
      window.location.reload();
    }
  }

  const dateLocal = JSON.parse(localStorage.getItem("addNewOrder"));

  // /////////////////////////////
  var today = new Date().toISOString().split("T")[0];

  const user = JSON.parse(localStorage.getItem("user"));

  const districts = useSelector((state) => state.districts);
  // //////////

  const changedate = () => {
    const data = [
      {
        code: dateLocal[0].code,
        delivey_date: delivey_date,
        comment: dateLocal[0].comment,
        qyt: dateLocal[0].qyt,
        total: dateLocal[0].total,
      },
    ];
    dispach(AddNewOrder(data));
  };
  var months = [
    "يناير",
    "فبراير",
    "مارس",
    "إبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  var days = [
    "اﻷحد",
    "اﻷثنين",
    "الثلاثاء",
    "اﻷربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];

  var date = new Date(dateLocal[0].delivey_date);
  let delDateString =
    days[date.getDay()] +
    ", " +
    date.getDate() +
    " " +
    months[date.getMonth()] +
    ", " +
    date.getFullYear();
  const options = [
    districts &&
      districts.data &&
      districts.data.map((item) => {
        return (
          //  {}
          {
            value: item.id,
            label: item[`name_${lang}`] + "- ( " + item.city_name + ")",
          }
        );
      }),
  ];
  const hendleingsub = (event) => {
    event.preventDefault();

    const data = {
      address: `${district.label} - ${detaileAddress}`,
      address_type,
      latitude: prodectData.latitude,
      longitude: prodectData.longitude,
      user_id: user.data.business_id,
      district: district.value,
      distValLab: district,
    };
    if (user.data && user.data.address1) {
      dispach(addressOrder(data));
      localStorage.setItem("address_order", JSON.stringify(data));
    }
    {
      dispach(addressOrder(data));
      dispach(addAddress(data, user.data.token));
      localStorage.setItem("address_order", JSON.stringify(data));
    }
  };
  return (
    <>
      <section className="SelectShipping container my-5">
        <div className="row">
          <div className="col col-12 col-md-7">
            <div className="cheked-data mt-3 pb-3">
              <div className="main-color text-start">
                {!mosque_data && edit && (
                  <button
                    onClick={() => {
                      setedit(!edit);
                    }}
                    className="btn main-color "
                  >
                    <MdModeEdit /> تعديل
                  </button>
                )}
              </div>
              <h5>بيانات الشحن</h5>

              {edit ? (
                <div>
                  <h6 className="main-color">
                    {" "}
                    {mosque_data ? mosque_data.name : user.data.user_name}
                  </h6>
                  <p> {prodectData && prodectData.address} </p>
                </div>
              ) : (
                <form
                  className="px-5"
                  onSubmit={(event) => hendleingsub(event)}
                >
                  <div>
                    <div className="col-12 mt-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="color"
                        options={options[0]}
                        onChange={(event) => {
                          setdistrict(event);
                        }}
                        value={
                          district && {
                            value: district.value,
                            label: district.label,
                          }
                        }
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <textarea
                        required
                        onChange={(event) =>
                          setdetaileAddress(event.target.value)
                        }
                        type="text"
                        className="form-control"
                        placeholder="العنوان بالتفصيل"
                        name=""
                        id=""
                        cols="10"
                        rows="5"
                        value={detaileAddress}
                      ></textarea>
                    </div>

                    <div className="col-12 mt-3">
                      <select
                        required
                        onChange={(event) => seaddress_type(event.target.value)}
                        className="form-control"
                        name=""
                        value={address_type}
                      >
                        <option value="1">المنزل</option>
                        <option value="2">العمل</option>
                        <option value="3">مكان عام </option>
                      </select>
                    </div>
                  </div>
                  <button
                    type="submit"
                    onClick={(event) => {
                      setedit(!edit);
                      hendleingsub(event);
                    }}
                    className="btn main-color text-center "
                  >
                    <MdModeEdit /> حفظ التعديل{" "}
                  </button>
                </form>
              )}
            </div>
            <div className="cheked-data mt-3">
              {/* //////////////////////////////////////////////////////// */}
              {!mosque_data && (
                <div className="text-start">
                  <button
                    onClick={() => {
                      seteditDate(!editDate);
                      changedate();
                    }}
                    className="main-color btn text-start"
                  >
                    <MdModeEdit />
                    تعديل
                  </button>{" "}
                </div>
              )}
              <h5>بيانات الطلب</h5>
              <div>
                حجز طلب يحتوي علي {dateLocal[0].qyt} عناصر | يصل
                {editDate ? (
                  <input
                    min={today}
                    defaultValue={dateLocal[0].delivey_date}
                    onChange={(event) => sedelivey_date(event.target.value)}
                    type="date"
                    className={"form-control   date-input  ml-4 p-2 mt-2 mb-2"}
                    id="birthday"
                    name="birthday"
                    placeholder="التاريخ والوقت"
                  />
                ) : (
                  <span className="main-color"> {delDateString}</span>
                )}{" "}
                | التكلفة{" "}
                <span className="main-color">
                  {detalisTotal.total + detalisTotal.shipping} ر.س
                </span>
              </div>
            </div>
            <div
              style={
                wayPay == "notSelcted"
                  ? { border: "1px solid red" }
                  : { border: "1px solid #bdffbd" }
              }
              className="cheked-data mt-3"
            >
              <h5>وسيلة الدفع</h5>
              <p>
                من فضلك اختر وسيلة الدفع التي سوف تدفع بها{" "}
                <span className="main-color">
                  {" "}
                  {detalisTotal.total + detalisTotal.shipping} ر.س
                </span>
              </p>
              <div className="form-check d-flex">
                <input
                  onChange={() => setwayPay("visa")}
                  className="form-check-input"
                  type="radio"
                  name="gridRadios"
                  id="gridRadios1"
                  value="option1"
                />
                <label className="form-check-label mr-5" for="gridRadios1">
                  الدفع visa
                </label>
              </div>
              {/* <p>من فضلك اضغط علي ايقونة وسيلة الدفع التي ستدفع من خلالها</p> */}
              <div>
                <button className="btn">
                  <img src={Visa} alt="" />
                </button>
                <button className="btn">
                  <img src={Stc} alt="" />
                </button>
                <button className="btn">
                  <img src={MasterCard} alt="" />
                </button>
                <button className="btn">
                  <img src={Mada} alt="" />
                </button>
              </div>
              {!mosque_data && (
                <>
                  <div className="form-check d-flex">
                    <input
                      onChange={() => setwayPay("Cash")}
                      className="form-check-input"
                      type="radio"
                      name="gridRadios"
                      id="gridRadios1"
                      value="option1"
                    />
                    <label className="form-check-label mr-5" for="gridRadios1">
                      الدفع عند الاستلام
                    </label>
                  </div>
                  <p>
                    ستدفع قيمة الطلب{" "}
                    <span className="main-color">
                      {detalisTotal.total + detalisTotal.shipping} ر.س
                    </span>{" "}
                    للمندوب عندما يصل اليك
                  </p>
                </>
              )}
            </div>
            <button onClick={checkout} className="btn add-card w-50 mt-3">
              {" "}
              الدفع
            </button>
          </div>

          <div className="col col-12 col-md-5">
            <div className="totale-prodects">
              <h5 className="main-color">ملخص الطلب</h5>
              <div className="d-flex justify-content-between ">
                <div>تكلفة الطلب:</div>
                <div>{detalisTotal.price.toFixed(2)} ر.س</div>
              </div>
              <hr />
              <div className="d-flex justify-content-between ">
                <div> التوصيل:</div>
                <div>{detalisTotal.shipping} ر.س</div>
              </div>
              <hr />
              <div className="d-flex justify-content-between ">
                <div> الضريبة:</div>
                <div> {detalisTotal.tax_amount.toFixed(2)}ر.س</div>
              </div>
              <hr />
              <div className="d-flex justify-content-between ">
                <div> الاجمالي:</div>
                <div className="main-color">
                  {detalisTotal.shipping >= 0
                    ? (detalisTotal.total + detalisTotal.shipping).toFixed(2)
                    : detalisTotal.total.toFixed(2)}{" "}
                  ر.س
                </div>
              </div>

              <button onClick={checkout} className="btn add-card w-50 mt-3">
                {" "}
                الدفع
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SelectShipping;
