import React, { useEffect } from 'react'

export default function ReplacementRefund() {
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  return (
    <>
      <section className='container'>
        <h2 className='text-center my-5'>سياسة الاستبدال والاسترجاع</h2>
        <h4 className='main-color '>هل هناك إمكانية استبدال أو استرجاع المنتج بعد الشراء؟</h4>
        <p className='fs-5'>أجل، عند وجود خطأ واضح في الصنع.</p>
        <h4 className='main-color'>شروط استبدال واسترجاع المنتج</h4>
        <ol className='fs-5'>
          <li> يحق للعميل إرجاع المنتج خلال ثلاث أيام من تاريخ الاستلام.</li>
          <li> يلزم أن يكون بحالته الأصلية ولم يتم فتحه أو استخدامه.</li>
          <li> إحضار فاتورة الشراء.</li>
          <li>في حالة وجود عيوب في المنتج ناتجة عن سوء الاستخدام أو التخزين لن يتم استرجاع أو استبدال المنتج.</li>
        </ol>
        <h4 className='main-color'>ما هي طريقة إرجاع المنتج؟</h4>
        <p className='fs-5'>عن طريق التواصل مع خدمة العملاء: 920006158</p>
        <h4 className='main-color'>كيف تسترجع المبلغ الذي قمت بدفعه؟</h4>
        <p className='fs-5'>استرجاع المبلغ عن طريق البطاقة الائتمانية أو نقدًا.</p>
        <h4 className='main-color'>ماذا تفعل عند حدوث أي خلل في إرجاع المبلغ لك؟</h4>
        <p className='fs-5'>في تلك الحالة يمكنك مراجعة البنك.</p>
        <h4 className='main-color'>هل يتم إرجاع منتجات العروض أو التخفيضات؟</h4>
        <p className='fs-5'>لا يمكن استرجاع أو استبدال منتجات العروض.</p>

      </section>
    </>
  )
}
