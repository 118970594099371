import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddCart, AddCartfree } from "../../services/action/action";
import { RiAddFill } from "react-icons/ri";
import { IoMdRemove } from "react-icons/io";
import "./style.css"
const AddREmoveItem = memo(({indexP,setcontentpop,sethidden}) => {
  const dispatch = useDispatch()

  
  const [cart, setcart] = useState('')
  const [ quntaty, setquntaty] = useState('')
  
 
 

  const carShoping = useSelector(state => state.cards);
   const  itemsfree = JSON.parse(localStorage.getItem('itemsfree'));
    useEffect(() => {
    setcart(carShoping)
    setquntaty(carShoping[indexP] && indexP >= 0 && carShoping[indexP].quantity || carShoping.quantity)
   }, [carShoping])
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const addItem = (index) => {
  
     
    cart[index].quantity = cart[index].quantity + 1;
    dispatch(AddCart(cart))
     const test=cart[index].presents
          for(let i =0 ;i < test.length; i++){
      
         if(test[i].qty != test[0].qty && test[i + 1] &&  cart[index].quantity < test[i + 1].qty && test[i + 1] &&  test[i].qty < test[i + 1].qty &&  cart[index].quantity  >= test[i].qty  && test[i].qty < test[test.length - 1].qty ){
             
               if (itemsfree) {
                //  get index numberitemsfree 
                if (itemsfree.find((product) => product.id === cart[index].id)) {
         
                  let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
                  itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
                  dispatch(AddCartfree(itemsfree))
                 
                } else {
                  cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
        
                  dispatch(AddCartfree([...itemsfree, cart[index]]))
          
                }
          
              } else {
        
                cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
                 dispatch(AddCartfree([cart[index]]))
              
             }
             if(test[i].qty == cart[index].quantity ||  (cart[index].quantity / test[i].qty ) % 1 == 0){
              !sethidden && document.getElementById("btn-test").click()
              const content =(
                <>
               <p>لقت تم  اضافة  عدد  <strong> {parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift}</strong> كرتون  مجانا   </p>
                <p>من عرض {cart[index].product_ar_name}</p>
                </>
              )
              setcontentpop && setcontentpop( content)
              sethidden && sethidden(true)
             } 
           
        }else if(test[i].qty == test[0].qty && test[i + 1] &&  cart[index].quantity < test[i + 1].qty && test[i + 1] &&  test[i].qty < test[i + 1].qty &&  cart[index].quantity   >= test[i].qty   && test[i].qty < test[test.length - 1].qty ){
          if(  (test[i].qty == cart[index].quantity   || cart[index].quantity / test[i].qty ) % 1 == 0){
            !sethidden &&  document.getElementById("btn-test").click()
            const content =(
              <>
             <p>لقت تم  اضافة  عدد  <strong> {parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift}</strong> كرتون  مجانا   </p>
              <p>من عرض {cart[index].product_ar_name}</p>
              </>
            )
            setcontentpop && setcontentpop( content)
            sethidden && sethidden(true)
           }
          if (itemsfree) {
              //  get index numberitemsfree 
              if (itemsfree.find((product) => product.id === cart[index].id)) {
          
                let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
                itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
                dispatch(AddCartfree(itemsfree))
               
              } else {
                
                cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
      
                dispatch(AddCartfree([...itemsfree, cart[index]]))
                
        
              }
        
            } else {
      
              cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
               dispatch(AddCartfree([cart[index]]))
            
           }
           
        }else if(test[i].qty == test[test.length - 1].qty && test[i - 1] &&  cart[index].quantity > test[i - 1].qty &&  test[i].qty > test[i - 1].qty &&  cart[index].quantity  >= test[i].qty   ){
        
          if (itemsfree) {
          //  get index numberitemsfree 
          if (itemsfree.find((product) => product.id === cart[index].id)) {
      
            let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
            itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
            dispatch(AddCartfree(itemsfree))
           
          } else {
            cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
      
            dispatch(AddCartfree([...itemsfree, cart[index]]))
      
          }
      
        } else {
      
          cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
           dispatch(AddCartfree([cart[index]]))
        
       }
       if(test[i].qty == cart[index].quantity  ||  (cart[index].quantity / test[i].qty ) % 1 == 0){
           !sethidden &&  document.getElementById("btn-test").click()
          const content =(
            <>
           <p>لقت تم  اضافة  عدد  <strong> {parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift}</strong> كرتون  مجانا   </p>
            <p>من عرض {cart[index].product_ar_name}</p>
            </>
          )
          setcontentpop && setcontentpop( content)
          sethidden && sethidden(true)
         }
         
       }else if(test[i].qty == test[0].qty && test[i + 1] &&  cart[index].quantity < test[i + 1].qty && test[i + 1] &&  test[i].qty < test[i + 1].qty &&  cart[index].quantity  < test[i].qty  && test[i].qty < test[test.length - 1].qty ){
         
        if (itemsfree) {
             if (itemsfree.find((product) => product.id === cart[index].id)) {
              const removeitem =   itemsfree.filter((item) =>{
                  return item.id != cart[index].id
                })
                 dispatch(AddCartfree(removeitem))
               !sethidden &&  document.getElementById("btn-test").click()
              const content =(
                <>
                <p>لقت تم حذف عدد <strong> {test[i].gift}</strong>  من المنتجات المجانية </p>
                <p>من عرض {cart[index].product_ar_name}</p>
                </>
              )
              setcontentpop &&  setcontentpop( content)
              sethidden && sethidden(true)
          
             
            }  
      
          }  
    
      }else if(test[i].qty == test[0].qty && test.length == 1 && cart[index].quantity >= test[0].qty ){
        if(test[i].qty == cart[index].quantity ||  (cart[index].quantity / test[i].qty ) % 1 == 0){
           !sethidden &&  document.getElementById("btn-test").click()
          const content =(
            <>
           <p>لقت تم  اضافة  عدد  <strong> {parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift}</strong> كرتون  مجانا   </p>
            <p>من عرض {cart[index].product_ar_name}</p>
            </>
          )
          setcontentpop &&  setcontentpop( content)
          sethidden && sethidden(true)
  
         }
        if (itemsfree) {
            //  get index numberitemsfree 
            if (itemsfree.find((product) => product.id === cart[index].id)) {
     
              let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
              itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
              dispatch(AddCartfree(itemsfree))
             
            } else {
              cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
    
              dispatch(AddCartfree([...itemsfree, cart[index]]))
      
            }
      
          } else {
    
            cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
             dispatch(AddCartfree([cart[index]]))
          
         }
        
      }else if(test[i].qty == test[0].qty && test.length == 1 && cart[index].quantity < test[0].qty ){
 
              if (itemsfree) {
              if (itemsfree.find((product) => product.id === cart[index].id)) {
                  const removeitem =   itemsfree.filter((item) =>{
                      return item.id != cart[index].id
                    })
                      dispatch(AddCartfree(removeitem))
                 !sethidden &&  document.getElementById("btn-test").click()
                const content =(
                  <>
                  <p>لقت تم حذف عدد <strong> {test[i].gift}</strong>  من المنتجات المجانية </p>
                  <p>من عرض {cart[index].product_ar_name}</p>
                  </>
                )
                setcontentpop &&  setcontentpop( content)
                sethidden && sethidden(true)
         
   
            }  
      
          } 
    
      }
      }
    }
  const removeItem = (index) => {
    const quantitylocal = cart[index].minimum  == 0 ? 1 : cart[index].minimum
    if (cart[index].quantity > quantitylocal) {
      cart[index].quantity = cart[index].quantity - 1;
      dispatch(AddCart(cart))
    }else{
       !sethidden &&  document.getElementById("btn-test").click()
      setcontentpop && setcontentpop( <h5>الحد الادني للطلب  {quantitylocal} كرتون </h5>)
      sethidden && sethidden(true)
    }
    const test=cart[index].presents
   
      for(let i =0 ;i < test.length; i++){
    
       if(test[i].qty != test[0].qty && test[i + 1] &&  cart[index].quantity < test[i + 1].qty && test[i + 1] &&  test[i].qty < test[i + 1].qty &&  cart[index].quantity  >= test[i].qty  && test[i].qty < test[test.length - 1].qty ){
              if (itemsfree) {
              //  get index numberitemsfree 
              if (itemsfree.find((product) => product.id === cart[index].id)) {
       
                let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
                itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
                dispatch(AddCartfree(itemsfree))
               
              } else {
                cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
      
                dispatch(AddCartfree([...itemsfree, cart[index]]))
        
              }
        
            } else {
      
              cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
               dispatch(AddCartfree([cart[index]]))
            
           }
           if(test[i].qty == cart[index].quantity ||  (cart[index].quantity / test[i].qty ) % 1 == 0){
             !sethidden &&  document.getElementById("btn-test").click()
            const content =(
              <>
             <p>لقت تم  اضافة  عدد  <strong> {parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift}</strong> كرتون  مجانا   </p>
              <p>من عرض {cart[index].product_ar_name}</p>
              </>
            )
            setcontentpop &&  setcontentpop( content)
            sethidden && sethidden(true)
           } 
         
      }else if(test[i].qty == test[0].qty && test[i + 1] &&  cart[index].quantity < test[i + 1].qty && test[i + 1] &&  test[i].qty < test[i + 1].qty &&  cart[index].quantity  >= test[i].qty  && test[i].qty < test[test.length - 1].qty ){
       
        if (itemsfree) {
            //  get index numberitemsfree 
            if (itemsfree.find((product) => product.id === cart[index].id)) {
     
              let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
              itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
              dispatch(AddCartfree(itemsfree))
             
            } else {
              cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
    
              dispatch(AddCartfree([...itemsfree, cart[index]]))
      
            }
      
          } else {
    
            cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
             dispatch(AddCartfree([cart[index]]))
          
         }
         if(test[i].qty == cart[index].quantity ||   (cart[index].quantity / test[i].qty ) % 1 == 0){
             !sethidden &&  document.getElementById("btn-test").click()
            const content =(
              <>
             <p>لقت تم  اضافة  عدد  <strong> {parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift}</strong> كرتون  مجانا   </p>
              <p>من عرض {cart[index].product_ar_name}</p>
              </>
            )
            setcontentpop &&  setcontentpop( content)
            sethidden && sethidden(true)
           }  
      }else if(test[i].qty == test[test.length - 1].qty && test[i - 1] &&  cart[index].quantity > test[i - 1].qty &&  test[i].qty > test[i - 1].qty &&  cart[index].quantity  >= test[i].qty   ){
     
        if (itemsfree) {
        //  get index numberitemsfree 
        if (itemsfree.find((product) => product.id === cart[index].id)) {
    
          let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
          itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
          dispatch(AddCartfree(itemsfree))
         
        } else {
          cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
    
          dispatch(AddCartfree([...itemsfree, cart[index]]))
    
        }
    
      } else {
    
        cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
         dispatch(AddCartfree([cart[index]]))
      
     }
     if(test[i].qty == cart[index].quantity ||   (cart[index].quantity / test[i].qty ) % 1 == 0){
         !sethidden &&  document.getElementById("btn-test").click()
        const content =(
          <>
         <p>لقت تم  اضافة  عدد  <strong> { parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift}</strong> كرتون  مجانا   </p>
          <p>من عرض {cart[index].product_ar_name}</p>
          </>
        )
        setcontentpop && setcontentpop( content)
        sethidden && sethidden(true)
       }
       
     }else if(test[i].qty == test[0].qty && test[i + 1] &&  cart[index].quantity < test[i + 1].qty && test[i + 1] &&  test[i].qty < test[i + 1].qty &&  cart[index].quantity  < test[i].qty  && test[i].qty < test[test.length - 1].qty ){
      
      if (itemsfree) {
           if (itemsfree.find((product) => product.id === cart[index].id)) {
            const removeitem =   itemsfree.filter((item) =>{
                return item.id != cart[index].id
              })
               dispatch(AddCartfree(removeitem))
             !sethidden &&  document.getElementById("btn-test").click()
            const content =(
              <>
              <p>لقت تم حذف عدد <strong> {test[i].gift}</strong>  من المنتجات المجانية </p>
              <p>من عرض {cart[index].product_ar_name}</p>
              </>
            )
            setcontentpop && setcontentpop( content)
            sethidden && sethidden(true)
        
           
          }  
    
        }  
  
    }else if(test[i].qty == test[0].qty && test.length == 1 && cart[index].quantity >= test[0].qty ){

      if (itemsfree) {
          //  get index numberitemsfree 
          if (itemsfree.find((product) => product.id === cart[index].id)) {
   
            let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
            itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
            dispatch(AddCartfree(itemsfree))
           
          } else {
            cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
  
            dispatch(AddCartfree([...itemsfree, cart[index]]))
    
          }
    
        } else {
  
          cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
           dispatch(AddCartfree([cart[index]]))
        
       }
       if(test[i].qty == cart[index].quantity ||   (cart[index].quantity / test[i].qty ) % 1 == 0){
         !sethidden &&  document.getElementById("btn-test").click()
        const content =(
          <>
         <p>لقت تم  اضافة  عدد  <strong> { parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift}</strong> كرتون  مجانا   </p>
          <p>من عرض {cart[index].product_ar_name}</p>
          </>
        )
        setcontentpop &&  setcontentpop( content)
            sethidden && sethidden(true)

       }
    }else if(test[i].qty == test[0].qty && test.length == 1 && cart[index].quantity < test[0].qty ){
            if (itemsfree) {
            if (itemsfree.find((product) => product.id === cart[index].id)) {
                const removeitem =   itemsfree.filter((item) =>{
                    return item.id != cart[index].id
                  })
                    dispatch(AddCartfree(removeitem))
               !sethidden &&  document.getElementById("btn-test").click()
              const content =(
                <>
                <p>لقت تم حذف عدد <strong> {test[i].gift}</strong>  من المنتجات المجانية </p>
                <p>من عرض {cart[index].product_ar_name}</p>
                </>
              )
              setcontentpop && setcontentpop( content)
              sethidden && sethidden(true)
       
 
          }  
    
        } 
  
    }
    }
 
  }

  const changeInputCountaty = (index , quantity) => {
  
       if(cart[index].presents[0] && cart[index].minimum  > quantity && cart[index].minimum == cart[index].presents[0].qty){
        if (itemsfree) {
          //  get index numberitemsfree 
          if (itemsfree.find((product) => product.id === cart[index].id)) {
   
            let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
            itemsfree[findindex].quantity = cart[index].presents[0].gift;
            dispatch(AddCartfree(itemsfree))
           
          } else {
            cart[index].quantity = cart[index].presents[0].gift
  
            dispatch(AddCartfree([...itemsfree, cart[index]]))
    
          }
    
        } else {
  
          cart[index].quantity = cart[index].presents[0].gift
           dispatch(AddCartfree([cart[index]]))
        
       }
      }
   
   cart[index].quantity = Number(quantity) ;
        dispatch(AddCart(cart))
        setTimeout(() =>{
          const quantitylocal = cart[index].minimum  == 0 ? 1 : cart[index].minimum
    
          if(quantity < quantitylocal ){
            cart[index].quantity =quantitylocal ;
            dispatch(AddCart(cart))
            !sethidden && document.getElementById("btn-test").click()
            setcontentpop && setcontentpop( <h5>الحد الادني للطلب  {quantitylocal} كرتون </h5>)
            sethidden && sethidden(true)
           }else{
            cart[index].quantity =Number(quantity) ;
        dispatch(AddCart(cart))
        // add  cart free
       
          }
      
        },
        1400)
     
     
     
    
     const test=cart[index].presents
        for(let i =0 ;i < test.length; i++){
      
         if(test[i].qty != test[0].qty && test[i + 1] &&  cart[index].quantity < test[i + 1].qty && test[i + 1] &&  test[i].qty < test[i + 1].qty &&  cart[index].quantity  >= test[i].qty  && test[i].qty < test[test.length - 1].qty ){
          const totalgift = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift

               if (itemsfree) {
                //  get index numberitemsfree 
                if (itemsfree.find((product) => product.id === cart[index].id)) {
         
                  let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
                  itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
                  dispatch(AddCartfree(itemsfree))
                 
                } else {
                  cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
        
                  dispatch(AddCartfree([...itemsfree, cart[index]]))
          
                }
          
              } else {
        
                cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
                 dispatch(AddCartfree([cart[index]]))
              
             }
           setTimeout(() =>{
            !sethidden  &&   document.getElementById("btn-test").click()
              const content =(
                <>
               <p>لقت تم  اضافة  عدد  <strong> {totalgift}</strong> كرتون  مجانا   </p>
                <p>من عرض {cart[index].product_ar_name}</p>
                </>
              )
              setcontentpop && setcontentpop( content)
              sethidden && sethidden(true)
           },1400)
           
              
           
        }else if(test[i].qty == test[0].qty && test[i + 1] &&  cart[index].quantity < test[i + 1].qty && test[i + 1] &&  test[i].qty < test[i + 1].qty &&  cart[index].quantity   >= test[i].qty   && test[i].qty < test[test.length - 1].qty ){
           const totalgift = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
          setTimeout(() =>{
            !sethidden &&  document.getElementById("btn-test").click()
                       const content =(
                         <>
                        <p>لقت تم  اضافة  عدد  <strong> {totalgift}</strong> كرتون  مجانا   </p>
                         <p>من عرض {cart[index].product_ar_name}</p>
                         </>
                       )
                       setcontentpop && setcontentpop( content)
                       sethidden && sethidden(true)
                    },1400)
        
          if (itemsfree) {
              //  get index numberitemsfree 
              if (itemsfree.find((product) => product.id === cart[index].id)) {
          
                let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
                itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
                dispatch(AddCartfree(itemsfree))
               
              } else {
                
                cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
      
                dispatch(AddCartfree([...itemsfree, cart[index]]))
                
        
              }
        
            } else {
      
              cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
               dispatch(AddCartfree([cart[index]]))
            
           }
           
        }else if(test[i].qty == test[test.length - 1].qty && test[i - 1] &&  cart[index].quantity > test[i - 1].qty &&  test[i].qty > test[i - 1].qty &&  cart[index].quantity  >= test[i].qty   ){
          const totalgift = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift

          if (itemsfree) {
          //  get index numberitemsfree 
          if (itemsfree.find((product) => product.id === cart[index].id)) {
      
            let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
            itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
            dispatch(AddCartfree(itemsfree))
           
          } else {
            cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
      
            dispatch(AddCartfree([...itemsfree, cart[index]]))
      
          }
      
        } else {
      
          cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
           dispatch(AddCartfree([cart[index]]))
        
       }
       setTimeout(() =>{
        !sethidden &&  document.getElementById("btn-test").click()
                   const content =(
                     <>
                    <p>لقت تم  اضافة  عدد  <strong> {totalgift}</strong> كرتون  مجانا   </p>
                     <p>من عرض {cart[index].product_ar_name}</p>
                     </>
                   )
                   setcontentpop && setcontentpop( content)
                   sethidden && sethidden(true)
                },1400)
       
       }else if(test[i].qty == test[0].qty && test[i + 1] &&  cart[index].quantity < test[i + 1].qty && test[i + 1] &&  test[i].qty < test[i + 1].qty &&  cart[index].quantity  < test[i].qty  && test[i].qty < test[test.length - 1].qty  && cart[index].minimum != test[0].qty ){
 
        if (itemsfree) {
             if (itemsfree.find((product) => product.id === cart[index].id)) {
              const removeitem =   itemsfree.filter((item) =>{
                  return item.id != cart[index].id
                })
                 dispatch(AddCartfree(removeitem))
                 setTimeout(() =>{
                  !sethidden && document.getElementById("btn-test").click()
              const content =(
                <>
                <p>لقت تم حذف عدد <strong> {test[i].gift}</strong>  من المنتجات المجانية </p>
                <p>من عرض {cart[index].product_ar_name}</p>
                </>
              )
              setcontentpop &&  setcontentpop( content)
            sethidden && sethidden(true)
              },1400)
             
            }  
      
          }  
    
      }else if(test[i].qty == test[0].qty && test.length == 1 && cart[index].quantity >= test[0].qty ){
        const totalgift = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift

        if (itemsfree) {
            //  get index numberitemsfree 
            if (itemsfree.find((product) => product.id === cart[index].id)) {
     
              let findindex = itemsfree.findIndex(obj => obj.id === cart[index].id);
              itemsfree[findindex].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift;
              dispatch(AddCartfree(itemsfree))
             
            } else {
              cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
    
              dispatch(AddCartfree([...itemsfree, cart[index]]))
      
            }
      
          } else {
    
            cart[index].quantity = parseInt(cart[index].quantity / test[i].qty ) *  test[i].gift
             dispatch(AddCartfree([cart[index]]))
          
         } 
         setTimeout(() =>{
          !sethidden &&  document.getElementById("btn-test").click()
                     const content =(
                       <>
                      <p>لقت تم  اضافة  عدد  <strong> {totalgift}</strong> كرتون  مجانا   </p>
                       <p>من عرض {cart[index].product_ar_name}</p>
                       </>
                     )
                     setcontentpop && setcontentpop( content)
                     sethidden && sethidden(true)
                  },1400)
      }else if(test[i].qty == test[0].qty && test.length == 1 && cart[index].quantity < test[0].qty && cart[index].minimum != test[0].qty ){
 
              if (itemsfree) {
              if (itemsfree.find((product) => product.id === cart[index].id)) {
                  const removeitem =   itemsfree.filter((item) =>{
                      return item.id != cart[index].id
                    })
                      dispatch(AddCartfree(removeitem))
                      setTimeout(() =>{
                        !sethidden &&   document.getElementById("btn-test").click()
                const content =(
                  <>
                  <p>لقت تم حذف عدد <strong> {test[i].gift}</strong>  من المنتجات المجانية </p>
                  <p>من عرض {cart[index].product_ar_name}</p>
                  </>
                )
                setcontentpop &&  setcontentpop( content)
            sethidden && sethidden(true)
                },1400)
   
            }  
      
          } 
    
      }
      }
 
  
    }

  return (
   <>
        <button type="button" className="btn d-hidden" id="btn-test" data-bs-toggle="modal" data-bs-target="#freeprodect"></button>
 
    <button  aria-label="addItem" onClick={() => addItem(indexP)} className="btn rounded-circle add-btn " > <RiAddFill /> </button>
    
        {carShoping && <input  className="input-quntaty" type="number" onChange={(event) =>{ changeInputCountaty(indexP,event.target.value) ;setquntaty(event.target.value)}} value={quntaty}  />}
        <button  aria-label="removeItem" onClick={() => { removeItem(indexP) }} className="btn rounded-circle remove-btn"> <IoMdRemove /> </button>
   </>
  )
})

export default AddREmoveItem;