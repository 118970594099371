import React from "react";
import "./style.css";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ReactStars from "react-rating-stars-component";
const RetingPage = () => {
    const ratingChanged = (newRating) => {
    };


    return (
        <>
            <section className="Reting-section container">
                <h3>التقييمات (0)</h3>
                <div className="main-color">(   تقييم من العملاء 0 ) </div>

                <h4 className="main-color text-center">لا توجد تقييمات لهذا المنتج</h4>


                <div className="text-start"><button className="btn main-color">كل التقييمات <MdOutlineKeyboardArrowDown /></button></div>
            </section>
            <section className="form-rating container">
                <h4>اضافة تقييم</h4>
                <p>يرجي تقييم المنتج بالضغط علي النجوم</p>
                <p className="mb-0">معدل تقييمك</p>
                <div>
                    <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        activeColor="#ffd700"
                    />
                </div>
                <form className="row g-3 ">
                    <div className="col-12">
                        <label for="inputAddress" className="form-label">التقييم</label>
                        <textarea type="text" className="form-control" id="inputAddress" placeholder="اكتب شيئا" cols="30" rows="5"></textarea>
                    </div>
                    <div className="col-md-6 mt-4">
                        <label for="inputPassword4" className="form-label">الاسم</label>
                        <input type="text" placeholder="الاسم" className="form-control p-2" id="inputPassword4" />
                    </div>
                    <div className="col-md-6 mt-4">
                        <label for="inputEmail4" className="form-label">البريد الالكتروني</label>
                        <input type="email" placeholder="البريد الالكتروني" className="form-control p-2" id="inputEmail4" />
                    </div>


                    <div className="col-12 mt-4">
                        <button type="submit" className="btn btn-send-rating ">ارسال</button>
                    </div>
                </form>
            </section>

        </>
    )
}

export default RetingPage;
