import React, { memo } from "react";
import { Navigate, useLocation } from "react-router";

const PrivateRoute = memo(({children}) =>{
 const userLogn = JSON.parse(localStorage.getItem('userisLogin'));   
 const location = useLocation();


    return userLogn ? children : <Navigate to={"/signIn"} replace = {true} state={{from:location}}/>
})
export default PrivateRoute