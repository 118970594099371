import React, { useEffect, useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { BsArrowLeftCircle } from "react-icons/bs";
import { AiFillStar } from "react-icons/ai";
import "./style.css";
import { IoIosClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddCart, AddCartfree } from "../../services/action/action";
import AddREmoveItem from "../addREmoveItem/addREmoveItem";

const ShopingCar = () => {
  const [cart, setcart] = useState('');
  const [hidden, sethidden] = useState(false);
  const [contentpop, setcontentpop] = useState('')

  const dispatch = useDispatch();

  let lang = "ar";
  let val = JSON.parse(localStorage.getItem("items"));
  const  itemsfree = JSON.parse(localStorage.getItem('itemsfree'));

  useEffect(() => {
    dispatch(AddCart(val));
  }, []);


  const initialValue = 0;
  var total = 0;
  if (cart && cart.length > 0) {
    total = cart.reduce(
      (accumulator, current) =>
        accumulator + current.promotion_price * current.quantity,
      initialValue
    );
  }

  const remove = (iteme) => {
    const filtered = cart.filter((item) => item.id !== iteme.id);

    dispatch(AddCart(filtered));
    const filteredfree = itemsfree.filter(item => item.id !== iteme.id);
     dispatch(AddCartfree(filteredfree))
   };

  const carShoping = useSelector((state) => state.cards);
  useEffect(() => {
    setcart(carShoping);
  }, [carShoping]);

  return (

    <>
  
      <div
        className="offcanvas offcanvas-start shoping-car p-2  "
        data-bs-scroll="true"
    
        tabIndex="-1"
        id="offcanvasScrolling"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title main-color"
            data-bs-dismiss="offcanvas"
            id="offcanvasScrollingLabel"
          >
            {" "}
            <span className="arrow-right">
              <MdOutlineArrowBack />
            </span> 
            سلة المشتريات 
    
          </h5>
          <button
            type="button"
            className="btn main-color fs-2"
            data-bs-dismiss="offcanvas"
          >
            <BsArrowLeftCircle />
          </button>
        </div>
       { hidden &&
       <div className="pop-cart-div"> <div className="pop-cart">
    {contentpop} 
    <button onClick={()=> sethidden(false)} className="btn btn_pop_massge ">استمرار الطلب</button>
    </div> </div>}
        <hr />
        <div className="offcanvas-body  p-0">
          <div className="content-carts">
            {cart
              ? cart.map((item, index) => {
                  return (
              
                      <div key={item.id} className="item-prodect">
                        <div className="row   position-relative  align-items-center">
                            <div className="col-12 col-lg-4">
                            <div className="position-relative  ">
                              <div className="div-reting  position-absolute ">
                                
                                4.5 
                                <span className="star-active">
                                  <AiFillStar />
                                </span>
                              </div>
                              <img
                                className="border border-2 w-100  w-100 img-shoping rounded-3 "
                                width={200}
                               height={200}
                                src={item.image}
                                alt=""
                              />
                            </div>
                            </div>
                
                          <div className="col-12 col-lg-4">
                       
                            <div className="">
                              <h6 className="fh-1"> {item[`product_${lang}_name`]} </h6>
                              <p className="my-3">
                                {" "}
                                {item[`product_${lang}_desc`]}{" "}
                              </p>
                              <p className="fs-5 main-color">
                                {" "}
                                {item.promotion_price} ر.س
                              </p>
                            </div>
                          </div>
                          <div className="align-items-center fs-3 d-flex col-12 col-lg-4">
                      <AddREmoveItem sethidden={sethidden} setcontentpop={setcontentpop}   indexP={index}/>

                          </div>

                          <button
                            onClick={() => remove(item)}
                            className="close-icon btn  position-absolute "
                          >
                            <IoIosClose />
                          </button>
                        </div>
                        <hr />
                      </div>
              
                  );
                })
              : null}
              {itemsfree
              ? itemsfree.map((item) => {
                  return (
                 
                      <div key={item.id.toString()} className="item-prodect">
                        <div className="row   position-relative  align-items-center">
                            <div className="col-12 col-lg-4">
                            <div className="position-relative  ">
                              <div className="div-reting  position-absolute ">
                                
                                4.5 
                                <span className="star-active">
                                  <AiFillStar />
                                </span>
                              </div>
                              <img
                                className="border border-2 w-100  w-100 img-shoping rounded-3 "
                                width={200}
                               height={200}
                                src={item.image}
                                alt=""
                              />
                            </div>
                            </div>
                
                          <div className="col-12 col-lg-4">
                       
                            <div className="">
                              <h6 className="fh-1"> {item[`product_${lang}_name`]} </h6>
                              <p className="my-3">
                                {" "}
                                {item[`product_${lang}_desc`]}{" "}
                              </p>
                              <p className="fs-5 main-color">
                               (مجانا)
                              </p>
                            </div>
                          </div>
                          <div className="align-items-center fs-3 d-flex col-12 col-lg-4 justify-content-center">
                          {item.quantity}
                          </div>
                        </div>
                        <hr />
                      </div>
               
                  );
                })
              : null}
          </div>
          <div className="d-flex  justify-content-between total-price">
            <div className="fs-5">الاجمالي</div>
            <div className="main-color fs-4">{total} ر.س</div>
          </div>
          <Link to={"/ShopingCarPage"}>
            <button
              data-bs-dismiss="offcanvas"
              className="add-card btn w-100 mt-4"
            >
              الدفع
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};
export default ShopingCar;
