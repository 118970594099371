import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AddCart, AddCartfree } from "../../services/action/action";
 
export const AddCartHooks =() =>{
    const dispatch = useDispatch()
    const [isShowingAlert, setShowingAlert] = useState(false);
    const [contentpop, setcontentpop] = useState()

    const  itemsfree = JSON.parse(localStorage.getItem('itemsfree'));

    const addCart =useCallback( (iteme) => {
  
      const quantitylocal = iteme.minimum  == 0 ? 1 : iteme.minimum
  
        const items = JSON.parse(localStorage.getItem('items'));
        const data = {
          ...iteme,
          quantity:Number(quantitylocal) 
        }
        const datafree = {
          ...iteme,
          quantity:Number(quantitylocal) 
        }
       
        if (items) {
          if (items.find((product) => product.id === iteme.id)) {
  
          } else {
            dispatch(AddCart([...items, data]))
            setShowingAlert(true)
        
            
              // Send a View Content event to the Snapchat servers
              window.snaptr('track', 'ADD_CART', {
                content_name: iteme.product_ar_name,
                content_ids: [iteme.id],
                content_type: 'product',
                value: iteme.final_price,
                currency: 'SAR'
              });
        //  window.snaptr('track', 'PAGE_VIEW');

        
            if( iteme.presents && iteme.presents[0] && iteme.minimum == iteme.presents[0].qty ){
         
              const content =(
                <>
               <p>لقت تم  اضافة  عدد  <strong> {iteme.presents[0].gift}</strong> كرتون  مجانا   </p>
                <p>من عرض {iteme.product_ar_name}</p>
                </>
              )
             setcontentpop( content)
             document.getElementById("addCartfree").click()
             if (itemsfree) {
            //  get index numberitemsfree 
            if (itemsfree.find((product) => product.id === datafree.id)) {
      
              let findindex = itemsfree.findIndex(obj => obj.id === iteme.id);
              itemsfree[findindex].quantity =  iteme.presents[0].gift ;
              dispatch(AddCartfree(itemsfree))
             
            } else {
              datafree.quantity =iteme.presents[0].gift 
      
              dispatch(AddCartfree([...itemsfree, datafree]))
      
            }
      
          } else {
      
            datafree.quantity =  iteme.presents[0].gift 
             dispatch(AddCartfree([datafree]))
          
         }
       }
            setTimeout(() => {
              setShowingAlert(false)
  
            }, 3000)
          }
  
        } else {
           if( iteme.presents && iteme.presents[0] && iteme.minimum == iteme.presents[0].qty ){
         
                const content =(
                  <>
                 <p>لقت تم  اضافة  عدد  <strong> {iteme.presents[0].gift}</strong> كرتون  مجانا   </p>
                  <p>من عرض {iteme.product_ar_name}</p>
                  </>
                )
               setcontentpop( content)
               document.getElementById("addCartfree").click()
               if (itemsfree) {
              //  get index numberitemsfree 
              if (itemsfree.find((product) => product.id === datafree.id)) {
        
                let findindex = itemsfree.findIndex(obj => obj.id === iteme.id);
                itemsfree[findindex].quantity =  iteme.presents[0].gift ;
                dispatch(AddCartfree(itemsfree))
               
              } else {
                datafree.quantity =iteme.presents[0].gift 
        
                dispatch(AddCartfree([...itemsfree, datafree]))
        
              }
        
            } else {
        
              datafree.quantity =  iteme.presents[0].gift 
               dispatch(AddCartfree([datafree]))
            
           }
         }
          dispatch(AddCart([data]))
         
            // Send a View Content event to the Snapchat servers
            window.snaptr('track', 'ADD_CART', {
              content_name: iteme.product_ar_name,
              content_ids: [iteme.id],
              content_type: 'product',
              value: iteme.final_price,
              currency: 'SAR'
            });
   
        }
      
     
  
    },[])

      return [addCart,isShowingAlert,contentpop]
}